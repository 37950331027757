import { ReactElement } from 'react';
import { observer } from 'mobx-react';
import { Box, Container, Divider, Flex, Image, Stack, Text } from '@chakra-ui/react';
import { useRouter } from 'next/router';
import Banner from '../CMS/banners/Banner';
import DesktopNavigation from './DesktopNavigation';
import photoLabLogo from '../../public/assets/photo-lab-icon.png';
import { URLS } from 'utils/constants';
import ProxiedImage from 'components/common/ProxiedImage';
import { useStore } from 'store';
import LDLink from 'components/common/LDLink';
import { GTMGeneralEvent } from 'utils/gtmHelpers';

const Header = ({ simpleHeader }: { simpleHeader: boolean }): ReactElement | null => {
  const { cmsStore, extrasStore } = useStore();
  const { push } = useRouter();
  const { globalHeaderBanner, mainNavigation } = cmsStore;

  if (simpleHeader) {
    return (
      <Box display={{ base: 'none', md: 'block' }}>
        <Box backgroundColor="primary.default" paddingY="4">
          <Container maxW="container.xxl" paddingY="6" align="center">
            <Image src="/assets/ld-extras-logo.png" alt="LDExtras logo" />
          </Container>
        </Box>
        <Box bgColor="extras.olive" h="0.75em" w="100%" mt="0.25em" />
      </Box>
    );
  }
  return (
    <Box display={{ base: 'none', md: 'block' }}>
      {globalHeaderBanner && <Banner {...globalHeaderBanner} isGlobalHeaderBanner />}
      <Container maxW="container.xxl">
        <Flex justifyContent="space-between" paddingY="1">
          <Flex fontSize={{ base: 'sm', lg: 'md' }}>
            <LDLink href="https://www.londondrugs.com/" variant="upperDesktopHeaderLeft">
              Shop
            </LDLink>
            <LDLink href="https://pharmacy.londondrugs.com/" variant="upperDesktopHeaderLeft">
              Pharmacy
            </LDLink>
            <LDLink href="https://photolab.londondrugs.com/" variant="upperDesktopHeaderLeft">
              <Flex alignItems="center">
                <ProxiedImage
                  src={photoLabLogo}
                  width={16}
                  height={17}
                  layout="fixed"
                  alt="photolab Logo"
                />
                <Text as="span" marginLeft="1" color="inherit" fontWeight="inherit">
                  photolab
                </Text>
              </Flex>
            </LDLink>
            <LDLink
              href="/"
              variant="upperDesktopHeaderLeft"
              borderLeft="none"
              fontWeight="bold"
              backgroundColor="shades.50"
              _hover={{ bg: 'shades.100', textDecoration: 'none' }}
            >
              LD
              <Text as="span" fontStyle="italic" fontWeight="medium" color="extras.olive">
                Extras
              </Text>
            </LDLink>
          </Flex>
          <Flex alignItems="center">
            <LDLink
              href="https://www.londondrugs.com/store-flyers/eflyer.html"
              target="_blank"
              rel="noreferrer"
              paddingX="2"
              borderRight="1px"
              borderRightColor="shades.200"
            >
              <Text
                fontSize="sm"
                color="primary.dark"
                _hover={{ color: 'primary.darker', bg: 'shades.100' }}
              >
                Flyers
              </Text>
            </LDLink>
            <LDLink
              href="https://www.londondrugs.com/locations/"
              target="_blank"
              rel="noreferrer"
              paddingLeft="2"
            >
              <Text
                fontSize="sm"
                color="primary.dark"
                _hover={{ color: 'primary.darker', bg: 'shades.100' }}
              >
                Locations
              </Text>
            </LDLink>
          </Flex>
        </Flex>
      </Container>
      <Box backgroundColor="primary.default" paddingY="4">
        <Container maxW="container.xxl">
          <Flex justifyContent="space-between" alignItems="center">
            <Box flex={1}>
              <LDLink href="/" d="flex" py="24px">
                <Image src="/assets/ld-extras-logo.png" alt="LDExtras logo" />
              </LDLink>
            </Box>
            <Flex flex={1} justifyContent="flex-end">
              <Flex marginLeft="5" flex={1} alignItems="center" justifyContent="flex-end">
                {extrasStore?.isLoggedIn ? (
                  <Stack spacing="1" direction="row" justifyContent="center" alignItems="center">
                    <Text color="white" fontSize="sm" fontWeight="semibold">
                      Welcome back, {extrasStore?.firstName}
                    </Text>
                    <Box height="16px">
                      <Divider orientation="vertical" borderColor="shades.300" />
                    </Box>
                    <Text
                      as="button"
                      onClick={() => {
                        push(URLS.SIGN_IN);
                        // Delay to prevent flash of empty dashboard
                        setTimeout(() => {
                          extrasStore.logout();
                        }, 200);
                      }}
                      color="shades.250"
                      fontSize="sm"
                      fontWeight="semibold"
                      _hover={{ color: 'shades.400', textDecoration: 'none' }}
                    >
                      Sign Out
                    </Text>
                  </Stack>
                ) : (
                  <Text fontWeight="bold" fontSize="sm">
                    <LDLink href={URLS.SIGN_IN}>
                      <Text as="a" color="white" _hover={{ color: 'shades.200' }}>
                        Sign In
                      </Text>
                    </LDLink>
                    <Text as="span" color="shades.300">
                      {' '}
                      or{' '}
                    </Text>
                    <LDLink
                      href={URLS.SIGN_UP}
                      onClick={() => GTMGeneralEvent({ event: 'sign_up' })}
                    >
                      <Text as="a" color="shades.300" _hover={{ color: 'shades.400' }}>
                        Sign Up
                      </Text>
                    </LDLink>
                  </Text>
                )}
              </Flex>
            </Flex>
          </Flex>
        </Container>
      </Box>
      {mainNavigation && <DesktopNavigation navItems={mainNavigation} />}
    </Box>
  );
};

export default observer(Header);
