import { ReactElement } from 'react';
import {
  Box,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Stack,
  Text,
  useDisclosure
} from '@chakra-ui/react';
import { ArrowForwardIcon } from '@chakra-ui/icons';
import MDXComponent from 'components/Mdx';
import { ImageCMSData } from 'types/cms/fragments';
import ProxiedImage from 'components/common/ProxiedImage';
import LDThemeButton from 'components/common/LDThemeButton';
import LDLink from 'components/common/LDLink';

export interface FeatureSplitProps {
  buttonColor?: string;
  buttonText?: string;
  buttonTextLink?: string;
  headline?: string;
  headlineColor?: string;
  image: ImageCMSData;
  // True = left, false = right
  imageLocation: boolean;
  text?: string;
  textColor?: string;
  title: string;
  videoModal?: {
    url: string;
  };
}

const FeatureSplit = ({
  buttonColor,
  buttonText,
  buttonTextLink,
  headline,
  headlineColor,
  image,
  imageLocation,
  text,
  textColor,
  videoModal
}: FeatureSplitProps): ReactElement => {
  const videoModalControls = useDisclosure();
  const hasVideo = !!videoModal;
  return (
    <Flex
      width="100%"
      direction={{ base: 'column', md: !!imageLocation ? 'row' : 'row-reverse' }}
      textAlign={{ base: 'center', md: 'left' }}
    >
      {image && (
        <Box flex="0 0 40%" marginBottom={{ base: '5', md: '0' }}>
          <ProxiedImage
            className="feature-image"
            src={image.url}
            width={image.width}
            height={image.height}
            alt={image.title}
            layout="responsive"
          />
        </Box>
      )}
      <Stack
        spacing={4}
        flex={1}
        marginLeft={{
          base: '0',
          md: !!imageLocation ? '10' : '0',
          xl: !!imageLocation ? '20' : '0'
        }}
        marginRight={{
          base: '0',
          md: !!imageLocation ? '0' : '10',
          xl: !!imageLocation ? '0' : '20'
        }}
        justifyContent="center"
        paddingLeft={{ base: '0', xl: !!imageLocation ? '0' : '8rem' }}
        paddingRight={{ base: '0', xl: !!imageLocation ? '8rem' : '0' }}
      >
        {headline && (
          <Text
            fontWeight="bold"
            fontSize={{ base: 'xl', lg: '2xl' }}
            color={headlineColor || 'primary.default'}
          >
            {headline}
          </Text>
        )}
        {text && (
          <MDXComponent fontSize={{ base: 'sm', md: 'md' }} color={textColor || 'black'}>
            {text}
          </MDXComponent>
        )}
        {buttonText && buttonTextLink && (
          <Box d="flex" justifyContent={{ base: 'center', md: 'flex-start' }} pt={{ md: '1rem' }}>
            <LDLink href={buttonTextLink}>
              <LDThemeButton variant="ld" as="a" bgColor={buttonColor ?? 'primary.default'}>
                {buttonText}
              </LDThemeButton>
            </LDLink>
          </Box>
        )}
        {!buttonText && buttonTextLink && (
          <LDLink href={buttonTextLink}>
            <Box as="a" alignSelf="flex-end" aria-label="Forward arrow icon">
              <ArrowForwardIcon width="6" height="6" />
            </Box>
          </LDLink>
        )}
        {buttonText && hasVideo && (
          <Box d="flex" justifyContent={{ base: 'center', md: 'flex-start' }} pt={{ md: '1rem' }}>
            <LDThemeButton
              onClick={videoModalControls.onOpen}
              bgColor={buttonColor ?? 'primary.default'}
            >
              {buttonText}
            </LDThemeButton>
          </Box>
        )}
      </Stack>
      {hasVideo && (
        <Modal
          isOpen={videoModalControls.isOpen}
          onClose={videoModalControls.onClose}
          isCentered
          size="2xl"
        >
          <ModalOverlay />
          <ModalContent>
            <ModalCloseButton color="white" zIndex={1} />
            <ModalBody padding={0}>
              <video autoPlay controls muted>
                <source src={videoModal.url} type="video/mp4" />
              </video>
            </ModalBody>
          </ModalContent>
        </Modal>
      )}
    </Flex>
  );
};

export default FeatureSplit;
