import { Flex, Image, Stack, Text } from '@chakra-ui/react';
import { observer } from 'mobx-react';
import { ReactElement } from 'react';
import { useStore } from 'store';

import { LoggedInHomeBannerCMSData } from 'types/cms/fragments';

export interface LoggedInHomeBannerProps extends LoggedInHomeBannerCMSData {
  index: number;
}

const LoggedInHomeBanner = ({
  backgroundColor,
  headline,
  icon,
  text,
  index
}: LoggedInHomeBannerProps): ReactElement => {
  const { extrasStore } = useStore();
  const { rewardsSummary } = extrasStore?.profile;

  const isLifetimePointsBanner = text === 'lifetime points earned';
  let lifetimePoints = 0;
  if (isLifetimePointsBanner && rewardsSummary) {
    lifetimePoints = rewardsSummary?.rewardsEarnedLifetime;
  }

  return (
    <Flex
      height="100%"
      bg={backgroundColor}
      justifyContent="center"
      alignItems="center"
      paddingX="4"
      paddingTop="6"
      paddingBottom="5"
      borderRadius="md"
      justifySelf={{
        base: 'initial',
        md: index === 2 ? 'center' : 'initial',
        lg: 'initial'
      }}
      maxWidth={{
        base: 'initial',
        md: index === 2 ? '50%' : 'initial',
        lg: 'initial'
      }}
      gridColumnStart={{
        base: 'initial',
        md: index === 2 ? 'span 2' : 'initial',
        lg: 'initial'
      }}
    >
      <Stack direction="row" spacing="6" justifyContent="center" alignItems="center">
        {icon && <Image src={icon.url} height="60px" width="60px" alt={text} />}
        <Stack spacing="0">
          <Text
            color="white"
            fontSize={{ base: 'xl', lg: '2xl' }}
            lineHeight={{ base: '6', lg: '7' }}
          >
            {isLifetimePointsBanner ? Number(lifetimePoints).toLocaleString() : headline}
          </Text>
          <Text
            color="white"
            fontSize={{ base: 'lg', lg: 'xl' }}
            lineHeight={{ base: '5', lg: '6' }}
          >
            {text}
          </Text>
        </Stack>
      </Stack>
    </Flex>
  );
};

export default observer(LoggedInHomeBanner);
