import { Box, Stack, useMediaQuery } from '@chakra-ui/react';
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import { Swiper, SwiperSlide } from 'swiper/react';
import FeaturedPerk from 'components/MyExtras/FeaturedPerk';
import { UnknownObjectAny } from 'types/global.types';
import { ExtrasPerkData, ListItemsCMSData } from 'types/cms/fragments';

export interface FeaturedProductsCarouselPops extends UnknownObjectAny {
  cmsData?: ListItemsCMSData[];
  kobieData?: ExtrasPerkData[];
}

const FeaturedProductsCarousel = ({
  cmsData,
  kobieData
}: FeaturedProductsCarouselPops): JSX.Element => {
  const useKobie = !!kobieData;
  const contentData = useKobie ? kobieData : (cmsData as ExtrasPerkData[]);

  const [isMobile] = useMediaQuery('(max-width:991px )');

  return (
    <Stack width="100%" spacing={10}>
      <Box paddingX={{ base: '1', sm: '50px', lg: '0' }} position="relative">
        <Box
          className="swiper-button-prev"
          left={{ base: '-5px', sm: '0', xl: '-40px' }}
          top={{ base: '55%', sm: '30%' }}
          _after={{ content: '""' }}
          bg={{ base: 'rgba(0, 0, 0, 0.1)', sm: 'initial' }}
          borderRadius="50%"
          height={{ base: '30px', sm: '10' }}
          width={{ base: '30px', sm: '10' }}
        >
          <ChevronLeftIcon
            height={{ base: '30px', sm: '10' }}
            width={{ base: '30px', sm: '10' }}
            color={{ base: 'white', sm: 'shades.200' }}
          />
        </Box>
        <Box
          className="swiper-button-next"
          right={{ base: '-5px', sm: '0', xl: '-40px' }}
          top={{ base: '55%', sm: '30%' }}
          _after={{ content: '""' }}
          bg={{ base: 'rgba(0, 0, 0, 0.1)', sm: 'initial' }}
          borderRadius="50%"
          height={{ base: '30px', sm: '10' }}
          width={{ base: '30px', sm: '10' }}
        >
          <ChevronRightIcon
            height={{ base: '30px', sm: '10' }}
            width={{ base: '30px', sm: '10' }}
            color={{ base: 'white', sm: 'shades.200' }}
          />
        </Box>
        <Swiper
          slidesPerView={isMobile ? 2 : 4}
          spaceBetween={isMobile ? 24 : 60}
          navigation={{ nextEl: '.swiper-button-next', prevEl: '.swiper-button-prev' }}
        >
          {contentData?.map((product, index) => (
            <SwiperSlide key={`featured-perk-${product?.title}-${index}`}>
              <FeaturedPerk {...product} useKobie={useKobie} />
            </SwiperSlide>
          ))}
        </Swiper>
      </Box>
    </Stack>
  );
};

export default FeaturedProductsCarousel;
