import { Box, Flex, Skeleton, Stack, Text } from '@chakra-ui/react';
import ProxiedImage from 'components/common/ProxiedImage';
import { ReactElement } from 'react';

interface MemberStatusProps {
  balance: number;
  tierName: string;
  isReducedSize?: boolean;
}

const StatusText = ({ balance, tierName }: MemberStatusProps): ReactElement => {
  return (
    <Flex
      justifyContent="center"
      alignItems="center"
      position="absolute"
      top="0"
      left="0"
      right="0"
      bottom="0"
    >
      <Stack spacing="0">
        <Text
          textAlign="center"
          fontSize="2xl"
          color="primary.hover"
          fontWeight="semibold"
          lineHeight="8"
        >
          LD
          <Text as="span" color="primary.hover" fontStyle="italic">
            Extras
          </Text>{' '}
          {tierName}
        </Text>
        {typeof balance === 'number' ? (
          <Text
            textAlign="center"
            fontSize="60px"
            color="primary.default"
            fontWeight="semibold"
            lineHeight="60px"
          >
            {Number(balance) < 0 ? 0 : Number(balance).toLocaleString()}
          </Text>
        ) : (
          <Skeleton height="60px" />
        )}
        <Text
          textAlign="center"
          fontSize="2xl"
          color="shades.300"
          fontWeight="semibold"
          lineHeight="8"
        >
          Points
        </Text>
      </Stack>
    </Flex>
  );
};

const MemberStatus = ({ balance, tierName, isReducedSize }: MemberStatusProps): ReactElement => {
  return (
    <Box position="relative" width="100%">
      <Box display={{ base: 'none', md: 'flex' }}>
        <ProxiedImage
          src="/assets/member-status.svg"
          width={isReducedSize ? 280 : 344}
          height={isReducedSize ? 206 : 254}
          alt="Member status background"
        />
        <StatusText balance={balance} tierName={tierName} />
      </Box>
      <Box display={{ base: 'block', md: 'none' }} marginTop="30px">
        <Box position="relative" w="100%" h="150px" d="flex" justifyContent="center">
          <ProxiedImage
            src="/assets/member-status-mobile.png"
            alt="Member status background"
            layout="fill"
          />
        </Box>
        <StatusText balance={balance} tierName={tierName} />
      </Box>
    </Box>
  );
};

export default MemberStatus;
