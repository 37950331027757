import { AddIcon, CheckIcon } from '@chakra-ui/icons';
import { Button, Flex, Spinner, useToast } from '@chakra-ui/react';
import LDThemeButton from 'components/common/LDThemeButton';
import React, { ReactElement, useState } from 'react';
import { useStore } from 'store';
import { isFrequencyOffer } from 'utils';
import { GTMGeneralEvent } from 'utils/gtmHelpers';
import * as Sentry from '@sentry/react';

interface LoadOfferButtonProps {
  offerCode: string;
  accountOfferId?: number | undefined;
  startedDate?: Date | null;
  expiresDate?: Date | null;
  isRegistered?: boolean;
  height?: { base?: string; md?: string; lg?: string };
  width?: { base?: string; md?: string; lg?: string };
  updateLoadOffer: (index: any) => void;
}

//Added Load offer button component on the loadable offer page
const LoadOfferButton = ({
  offerCode,
  startedDate,
  expiresDate,
  isRegistered,
  width,
  height,
  updateLoadOffer,
  accountOfferId
}: LoadOfferButtonProps): ReactElement => {
  const { extrasStore } = useStore();
  const toast = useToast();
  const [isRedeeming, setIsRedeeming] = useState<boolean>(false);
  const [checkRegistered, setcheckRegistered] = useState(isRegistered);

  //Added register event to the load offer button
  const registerOffer = async (offerId: string) => {
    try {
      setIsRedeeming(true);
      const offerData = {
        startDate: startedDate,
        endDate: expiresDate
      };
      let response;
      if (offerCode?.includes("$")) {
        response = await extrasStore?.registerLoadableOffer(accountOfferId, offerData);
      } else {
        response = await extrasStore?.registerLoadableOffer(offerId, offerData);
      }
      if (response?.success) {
        GTMGeneralEvent({
          event: isFrequencyOffer(offerCode) ? `offer:featured:${offerCode}` : `offer:${offerCode}`,
          label: isFrequencyOffer(offerCode) ? `offer:featured:${offerCode}` : `offer:${offerCode}`,
          action: "Load Offer",
          category: "Offers",
        });
        updateLoadOffer(offerId)
        Sentry.captureMessage(`Offer has been registered, offerCode: ${offerCode}`);
        setcheckRegistered(true);
      } else {
        toast({
          title: 'Failed to register for offer',
          description: 'Please try again later',
          status: 'error',
          duration: 5000,
          isClosable: true
        });
        Sentry.captureMessage(`Failed to register for offer, offerCode: ${offerCode}`);
      }
    } catch (error) {
      console.error('Error registering offer');
      Sentry.captureMessage(`Error registering offer, offerCode: ${offerCode}`);
      console.error(error);
    } finally {
      setIsRedeeming(false);
    }
  };

  return (
    <>
      {checkRegistered ? (
        <Button
          leftIcon={<CheckIcon boxSize={{ base: 2, md: 3 }} />}
          variant="ldLoaded"
          as="a"
          height={height}
          width={width}
        >
          LOADED
        </Button>
      ) : (
        <>
          {isRedeeming ? (
            <Button variant="ldLoadable" as="a" width={width} height={height} disabled>
              <Flex justify="center" align="center">
                <Spinner />
              </Flex>
            </Button>
          ) : (
            <Button
              leftIcon={<AddIcon fontWeight="bold" boxSize={{ base: 2, md: 3 }} />}
              variant="ldLoadable"
              onClick={() => registerOffer(offerCode)}
              as="a"
              width={width}
              height={height}
            >
              LOAD OFFER
            </Button>
          )}
        </>
      )}
    </>
  );
};

export default React.memo(LoadOfferButton);
