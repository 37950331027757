import React, { ReactElement } from 'react';
import { Table, Thead, Tbody, Tfoot, Tr, Th, Td, Text } from '@chakra-ui/react';
import { CheckCircleIcon } from '@chakra-ui/icons';
import { UnknownObjectAny } from 'types/global.types';
import { ProgramBenefitItemCMSData } from 'types/cms/fragments';
import { EXTRAS_TIER_NAME } from 'utils/constants';

interface RewardTierTableProps {
  content: UnknownObjectAny;
}

const RewardTierTable = ({ content }: RewardTierTableProps): ReactElement => {
  return (
    <Table variant="ldextras">
      <Thead height={14} borderBottom="solid 1px white">
        <Tr>
          <Th color="extras.default" bgColor="white" fontSize="md" textTransform="none">
            Benefit
          </Th>
          <Th
            bgColor="extras.ltblue"
            color="white"
            borderTopLeftRadius={6}
            borderTopRightRadius={6}
            fontSize="xl"
            textAlign="center"
            textTransform="none"
            lineHeight="1"
          >
            <Text as="span" fontWeight="900" color="white">
              LD
            </Text>
            <Text as="i" color="white">
              Extras
            </Text>{' '}
            {EXTRAS_TIER_NAME.TIER1}
          </Th>
          <Th
            bgColor="extras.oliveDarker"
            color="white"
            borderTopLeftRadius={6}
            borderTopRightRadius={6}
            fontSize="xl"
            textAlign="center"
            textTransform="none"
            lineHeight="1"
          >
            <Text as="span" fontWeight="semibold" color="white">
              LD<i>Extras</i>
            </Text>{' '}
            {EXTRAS_TIER_NAME.TIER2}
          </Th>
        </Tr>
      </Thead>
      <Tbody>
        {content?.itemCollection?.items?.map(
          (
            { benefitName, tier1BenefitText, tier2BenefitText }: ProgramBenefitItemCMSData,
            index: number
          ) => {
            const shouldDisplayCheckTier1 = tier1BenefitText?.toLowerCase() === 'yes';
            const shouldDisplayCheckTier2 = tier2BenefitText?.toLowerCase() === 'yes';
            return (
              <Tr key={benefitName}>
                <Td>{benefitName}</Td>
                <Td
                  bgColor={index % 2 === 0 ? 'extras.ltblue' : 'extras.ltblueDark'}
                  color="white"
                  textAlign="center"
                  fontWeight="bold"
                >
                  {shouldDisplayCheckTier1 ? (
                    <CheckCircleIcon w={6} height={6} />
                  ) : (
                    tier1BenefitText
                  )}
                </Td>
                <Td
                  bgColor={index % 2 === 0 ? 'extras.oliveDarker' : 'extras.oliveDark'}
                  color="white"
                  textAlign="center"
                  fontWeight="bold"
                >
                  {shouldDisplayCheckTier2 ? (
                    <CheckCircleIcon w={6} height={6} />
                  ) : (
                    tier2BenefitText
                  )}
                </Td>
              </Tr>
            );
          }
        )}
      </Tbody>
    </Table>
  );
};

export default RewardTierTable;
