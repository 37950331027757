import { ReactElement } from 'react';
import { Container, Divider, Stack, Text } from '@chakra-ui/react';

import MemberInfo from './MemberInfo';
import MemberStatus from './MemberStatus';
import TierProgress from './TierProgress';
import PointsInfo from './PointsInfo';
import { useStore } from 'store';
import { observer } from 'mobx-react';
import RewardsAndVoucherMessage from './RewardsAndVoucherMessage';

export interface HeroCardData {
  memberId: string;
  memberSince: string;
  pointsInfo: {
    tierCode: 'TIER1' | 'TIER2' | string;
    tierExpireDate: string | null;
    canEarn: boolean;
    canRedeem: boolean;
    cardLastFour: string;
    balance: number;
    pending: number;
    ytdVisits: number;
    lifeVisits: number;
  };
  tierName: string;
  nextTierRequirements: {
    amountSpentTowardsNextTier?: number;
    remainingSpendRequirement?: number;
  };
  tierRequalificationRequirements: {
    amountSpentTowardsRequalification?: number;
    remainingSpendRequalificationRequirement?: number;
  };
  isRequalified: boolean;
}

export interface HeroCardProps {
  isRewardsAndVouchers?: boolean;
}

const HeroCard = ({ isRewardsAndVouchers }: HeroCardProps): ReactElement | null => {
  const { extrasStore } = useStore();
  const {
    memberId,
    memberSince,
    pointsInfo,
    tierName,
    nextTierRequirements,
    tierRequalificationRequirements,
    isRequalified
  } = extrasStore?.heroCardData();

  if (!memberId) {
    return null;
  }
  return (
    <Container
      maxW={!isRewardsAndVouchers ? 'container.standard' : '100%'}
      bg="white"
      borderRadius={
        !isRewardsAndVouchers ? { base: '20px', md: '30px' } : { base: '10px', md: '20px' }
      }
      boxShadow={{ base: '0px 4px 20px rgba(0, 0, 0, 0.25)' }}
      paddingX={!isRewardsAndVouchers ? { base: '4', md: '6' } : undefined}
      paddingTop={!isRewardsAndVouchers ? { base: '4', md: '8' } : { base: '4', md: '4' }}
      paddingBottom={!isRewardsAndVouchers ? { base: '10', md: '6' } : { base: '10', md: '4' }}
    >
      {/* Mobile */}
      <Stack display={{ base: 'flex', md: 'none' }}>
        <Stack spacing="2.5">
          {!isRewardsAndVouchers && (
            <>
              <MemberInfo memberId={memberId} memberSince={memberSince} />
              <Divider borderColor="shades.100" />
              <TierProgress tierName={tierName} tierCode={pointsInfo?.tierCode} />
            </>
          )}
        </Stack>
        <Stack spacing="5">
          <MemberStatus balance={pointsInfo?.balance} tierName={tierName} />
          {!isRewardsAndVouchers ? (
            <PointsInfo
              remainingProgress={nextTierRequirements?.remainingSpendRequirement}
              remainingRequalificationProgress={
                tierRequalificationRequirements?.remainingSpendRequalificationRequirement
              }
              isRequalified={isRequalified}
              tierCode={pointsInfo?.tierCode}
              balance={pointsInfo?.balance}
            />
          ) : (
            <RewardsAndVoucherMessage balance={pointsInfo?.balance} />
          )}
          {isRewardsAndVouchers && (
            <Text fontStyle="italic" fontSize="sm">
              *It may take up to 72 hours for points balance to adjust.
            </Text>
          )}
          {!isRewardsAndVouchers && (
            <Text
              fontStyle="italic"
              fontSize="sm"
              color="shades.300"
              align={{ base: 'left', md: 'right' }}
              marginTop={{ base: '4', md: 0 }}
            >
              *It may take up to 72 hours for points balance to adjust.
            </Text>
          )}
        </Stack>
      </Stack>
      {/* Desktop */}
      <Stack direction="row" spacing="10" display={{ base: 'none', md: 'flex' }}>
        {/* Member info & tier status bar */}
        <Stack>
          {!isRewardsAndVouchers && <MemberInfo memberId={memberId} memberSince={memberSince} />}
          <MemberStatus
            balance={pointsInfo?.balance}
            tierName={tierName}
            isReducedSize={isRewardsAndVouchers}
          />
        </Stack>
        {/* Status/Balance & Checkmarks and Rewards message*/}
        <Stack
          flex={1}
          spacing={!isRewardsAndVouchers ? { base: '30px', lg: '50px' } : '30px'}
          justify={isRewardsAndVouchers ? 'center' : 'initial'}
        >
          {!isRewardsAndVouchers && (
            <TierProgress tierName={tierName} tierCode={pointsInfo?.tierCode} />
          )}
          {!isRewardsAndVouchers ? (
            <PointsInfo
              remainingProgress={nextTierRequirements?.remainingSpendRequirement}
              remainingRequalificationProgress={
                tierRequalificationRequirements?.remainingSpendRequalificationRequirement
              }
              isRequalified={isRequalified}
              tierCode={pointsInfo?.tierCode}
              balance={pointsInfo?.balance}
            />
          ) : (
            <RewardsAndVoucherMessage balance={pointsInfo?.balance} />
          )}
          {isRewardsAndVouchers && (
            <Text fontStyle="italic" fontSize="sm" color="shades.300">
              *It may take up to 72 hours for points balance to adjust.
            </Text>
          )}
          {!isRewardsAndVouchers && (
            <Text
              fontStyle="italic"
              fontSize="sm"
              color="shades.300"
              align={{ base: 'left' }}
              marginTop={{ base: '4', md: 0 }}
            >
              *It may take up to 72 hours for points balance to adjust.
            </Text>
          )}
        </Stack>
      </Stack>
    </Container>
  );
};

export default observer(HeroCard);
