import { ReactElement } from 'react';
import { Box, Flex, Image, Stack, Text, useMediaQuery } from '@chakra-ui/react';
import { ArrowForwardIcon } from '@chakra-ui/icons';

import { TextBannerCMSData } from 'types/cms/fragments';
import { presetComponentProps } from 'theme';
import ProxiedImage from 'components/common/ProxiedImage';
import LDThemeButton from 'components/common/LDThemeButton';
import LDLink from 'components/common/LDLink';

interface TextBannerProps extends TextBannerCMSData {
  isTwoUp?: boolean;
}

const TextBanner = ({
  buttonText,
  buttonTextLink,
  buttonColor,
  image,
  headline,
  headlineColor,
  text,
  isTwoUp
}: TextBannerProps): ReactElement => {
  const [isMobile] = useMediaQuery('(max-width:767px )');
  const hasIcon = image?.url?.indexOf('.svg') !== -1;
  return (
    <Box borderRadius="lg" boxShadow="0px 8px 20px rgba(0, 0, 0, 0.1)">
      {isTwoUp ? (
        <Flex
          width="100%"
          height="100%"
          padding={{ base: '4', lg: '5' }}
          justifyContent="center"
          alignItems="center"
          flexDirection={{ base: 'column', md: 'row' }}
        >
          {image && (
            <>
              {hasIcon ? (
                <Box alignSelf="center" flex={2} marginRight={{ base: '0', md: '10' }}>
                  <Image
                    src={image?.url}
                    width="50px"
                    height="50px"
                    alt={image?.description || headline}
                  />
                </Box>
              ) : (
                <Box
                  flex={2}
                  marginRight={{ base: '0', md: '5' }}
                  marginBottom={{ base: '3', md: '0' }}
                  width="100%"
                  height="100%"
                >
                  <ProxiedImage
                    src={image?.url}
                    width={isMobile ? image?.width : 1}
                    height={isMobile ? image?.height : 1}
                    layout="responsive"
                    alt={image?.description}
                  />
                </Box>
              )}
            </>
          )}
          <Stack flex={3} spacing="2" height="100%">
            <Text
              {...presetComponentProps?.h3}
              fontWeight="bold"
              color={headlineColor || 'primary.default'}
            >
              {headline}
            </Text>
            {text && <Text fontSize={{ base: 'sm', md: 'md' }}>{text}</Text>}
            {buttonText ? (
              <LDLink href={buttonTextLink || '/'}>
                <Box as="a" aria-label={headline ? headline : `Link to ${buttonText}`}>
                  <LDThemeButton
                    paddingY="2"
                    bgColor={buttonColor || 'primary.default'}
                    fontWeight="bold"
                  >
                    {buttonText}
                  </LDThemeButton>
                </Box>
              </LDLink>
            ) : (
              <LDLink href={buttonTextLink || '/'}>
                <Box
                  as="a"
                  aria-label="Forward arrow"
                  alignSelf="flex-end"
                  display="flex"
                  alignItems="flex-end"
                  flex={1}
                >
                  <ArrowForwardIcon width={6} height={20} />
                </Box>
              </LDLink>
            )}
          </Stack>
        </Flex>
      ) : (
        <Stack spacing="4" height="100%" padding={{ base: '4', lg: '6' }}>
          <Stack spacing="2" height="100%">
            {image && (
              <>
                {hasIcon ? (
                  <Box alignSelf="center" mb={{ base: 2, lg: 4 }}>
                    <Image
                      src={image?.url}
                      width="50px"
                      height="50px"
                      alt={image?.description || headline}
                    />
                  </Box>
                ) : (
                  <Box>
                    <ProxiedImage
                      src={image?.url}
                      width={image?.width}
                      height={image?.height}
                      alt={image?.description}
                    />
                  </Box>
                )}
              </>
            )}
            <Text
              {...presetComponentProps?.h3}
              fontWeight="bold"
              color={headlineColor || 'primary.default'}
            >
              {headline}
            </Text>
            {text && <Text fontSize={{ base: 'sm', md: 'md' }}>{text}</Text>}
          </Stack>

          {buttonText ? (
            <LDLink href={buttonTextLink || '/'}>
              <Box as="a" aria-label={headline ? headline : `Link to ${buttonText}`}>
                <LDThemeButton
                  paddingY="2"
                  bgColor={buttonColor || 'primary.default'}
                  fontWeight="bold"
                >
                  {buttonText}
                </LDThemeButton>
              </Box>
            </LDLink>
          ) : (
            <LDLink href={buttonTextLink || '/'}>
              <Box as="a" alignSelf="flex-end" aria-label="Forward arrow">
                <ArrowForwardIcon width={6} height={6} />
              </Box>
            </LDLink>
          )}
        </Stack>
      )}
    </Box>
  );
};

export default TextBanner;
