import { CONTENT_TYPES } from 'utils/constants';

export const LOGGED_IN_HOME_BANNER = `
fragment ${CONTENT_TYPES.LOGGED_IN_HOME_BANNER}Fragment on ${CONTENT_TYPES.LOGGED_IN_HOME_BANNER} {
  __typename
  title
  text
  headline
  backgroundColor
  icon {
    ...Image
  }
}
`;

export const MEMBER_PANEL_BULLET_ITEM = `
fragment ${CONTENT_TYPES.MEMBER_PANEL_BULLET_ITEM}Fragment on ${CONTENT_TYPES.MEMBER_PANEL_BULLET_ITEM} {
  __typename
  title
  text
}
`;
