import {
  Text as ChakraText,
  Heading,
  UnorderedList,
  ListItem,
  Divider,
  OrderedList,
  Stack,
  Box,
  Flex
} from '@chakra-ui/layout';
import React, { ReactElement, ReactNode, ReactNodeArray } from 'react';
import { BLOCKS, Block, Inline, INLINES } from '@contentful/rich-text-types';
import { documentToReactComponents, Options } from '@contentful/rich-text-react-renderer';

import { presetComponentProps } from 'theme';
import { CONTENT_TYPES } from 'utils/constants';
import ImageInText from './text-entries/ImageInText';
import { UnknownObjectAny } from 'types/global.types';
import { TextCMSData } from 'types/cms/fragments';
import LDLink from 'components/common/LDLink';
import { ResponsiveValue } from '@chakra-ui/react';

export interface TextProps {
  data?: TextCMSData;
  overrideTextAlign?: boolean;
  overrideTextWidth?: boolean;
}

const Text = ({
  data,
  overrideTextAlign = false,
  overrideTextWidth = false
}: TextProps): ReactElement | null => {
  const entryMap = new Map();

  const lastNodeValueIsEmpty =
    (
      data?.content?.json?.content?.[data?.content?.json?.content?.length - 1]
        ?.content?.[0] as UnknownObjectAny
    )?.value === '';

  if (lastNodeValueIsEmpty) {
    // Used to remove empty paragraph node at the end of content array
    data?.content?.json?.content?.pop();
  }

  // Used to hand margin bottoms on last node item
  const isLastNode = (currentNode: UnknownObjectAny) =>
    data?.content?.json?.content?.[data?.content?.json?.content?.length - 1] === currentNode;

  if (data?.content?.links?.entries) {
    // create an entry map
    if (data?.content?.links?.entries?.block) {
      // loop through the block linked entries and add them to the map
      for (const entry of data?.content?.links?.entries?.block) {
        entryMap.set(entry?.sys?.id, entry);
      }
    }
  }

  const jsonSharedStyes = overrideTextWidth
    ? {}
    : {
        maxWidth: '861px !important',
        marginX: 'auto !important'
      };

  const options: Options = {
    renderNode: {
      [INLINES.HYPERLINK]: (node: Block | Inline, children: ReactNode) => {
        return (
          <LDLink color="primary.default" href={node?.data?.uri}>
            {children}
          </LDLink>
        );
      },
      [BLOCKS.HEADING_1]: () => null,
      [BLOCKS.HEADING_2]: (_node: Block | Inline, children: ReactNode) => {
        return (
          <Heading
            {...presetComponentProps?.h2}
            color="primary.medium"
            whiteSpace="pre-line"
            mt={0}
            mb="1rem"
            {...jsonSharedStyes}
          >
            {children}
          </Heading>
        );
      },
      [BLOCKS.HEADING_3]: (_node: Block | Inline, children: ReactNode) => (
        <Heading
          {...presetComponentProps.h3}
          color="default"
          whiteSpace="pre-line"
          mt={0}
          mb="1rem"
          {...jsonSharedStyes}
        >
          {children}
        </Heading>
      ),
      [BLOCKS.HEADING_4]: (_node: Block | Inline, children: ReactNode) => {
        return null;
      },
      [BLOCKS.HEADING_5]: (_node: Block | Inline, children: ReactNode) => {
        return null;
      },
      [BLOCKS.HEADING_6]: (_node: Block | Inline, children: ReactNode) => {
        return null;
      },
      [BLOCKS.HR]: () => <Divider my="2em" color="shade.300" w="100%" />,
      [BLOCKS.PARAGRAPH]: (_node: Block | Inline | UnknownObjectAny, children: ReactNode) => {
        const filteredChildren = (
          Array.isArray(children) ? (children as ReactNodeArray) : undefined
        )?.filter((component: ReactNode) => component !== '');
        if (filteredChildren?.length === 0) return null;

        let textAlign = 'center';
        if (overrideTextAlign) {
          textAlign = 'left';
        }

        return (
          <ChakraText
            textAlign={textAlign as ResponsiveValue<'center' | 'left'>}
            whiteSpace="pre-line"
            mt="0 !important"
            mb={isLastNode(_node) ? 0 : '1rem !important'}
            width="100%"
            {...jsonSharedStyes}
          >
            {filteredChildren}
          </ChakraText>
        );
      },
      [BLOCKS.QUOTE]: (node: Block | Inline | UnknownObjectAny) => {
        return null;
      },
      [BLOCKS.OL_LIST]: (node: Block | Inline | UnknownObjectAny, children: ReactNode) => {
        // Used to determined h6 tags, all h6 tags are used to make small grayed out text.
        const firstNodeIsH6 = node?.content?.[0]?.content?.[0]?.nodeType === BLOCKS.HEADING_6;

        return (
          <OrderedList
            spacing={3}
            fontSize={firstNodeIsH6 ? '0.75rem' : undefined}
            color={firstNodeIsH6 ? '#858686' : undefined}
            maxW={jsonSharedStyes?.maxWidth}
            mb={isLastNode(node) ? 0 : '1rem !important'}
            textAlign="left"
            paddingLeft={5}
            {...jsonSharedStyes}
          >
            {children}
          </OrderedList>
        );
      },
      [BLOCKS.UL_LIST]: (node: Block | Inline | UnknownObjectAny, children: ReactNode) => {
        // Used to determined h6 tags, all h6 tags are used to make small grayed out text.
        const firstNodeIsH6 = node?.content?.[0]?.content?.[0]?.nodeType === BLOCKS.HEADING_6;

        let textAlign = 'center';
        if (overrideTextAlign) {
          textAlign = 'left';
        }

        return (
          <UnorderedList
            ml={{ base: '1.5rem !important', md: '2rem !important' }}
            textAlign={textAlign as ResponsiveValue<'center' | 'left'>}
            spacing={1}
            mb={isLastNode(node) ? 0 : '1rem !important'}
            fontSize={firstNodeIsH6 ? '0.75rem' : undefined}
            color={firstNodeIsH6 ? '#858686' : undefined}
            width="100%"
          >
            {children}
          </UnorderedList>
        );
      },
      [BLOCKS.LIST_ITEM]: (_node: Block | Inline, children: ReactNode) => {
        return <ListItem>{children}</ListItem>;
      },
      [BLOCKS.EMBEDDED_ENTRY]: (node: Block | Inline) => {
        const entryData = entryMap?.get(node?.data?.target?.sys?.id);
        let RenderComponent;

        switch (entryData?.__typename) {
          case CONTENT_TYPES.IMAGE_IN_TEXT:
            RenderComponent = () => <ImageInText {...entryData} />;
            break;
          default:
            RenderComponent = () => null;
            break;
        }

        return <RenderComponent />;
      }
    }
  };

  if (!data) return null;

  return (
    <Flex flexDir="column" justifyContent="center">
      <Stack
        spacing={'0.5rem'}
        mx="auto"
        textAlign="center"
        d="flex"
        maxW={jsonSharedStyes?.maxWidth}
      >
        {documentToReactComponents(data?.content?.json, options)}
      </Stack>
    </Flex>
  );
};

export default Text;
