import { Skeleton, Stack, Text } from '@chakra-ui/react';
import { ReactElement } from 'react';

interface MemberInfoProps {
  memberSince: string;
  memberId: string;
}

const MemberInfo = ({ memberSince, memberId }: MemberInfoProps): ReactElement => (
  <Stack spacing="1" alignItems={{ base: 'center', md: 'initial' }}>
    {memberId ? (
      <Text color="primary.default" fontWeight="bold" fontSize={{ base: 'md', md: 'lg' }}>
        Your Member ID:{' '}
        <Text as="span" fontWeight="normal" color="primary.default">
          {memberId}
        </Text>
      </Text>
    ) : (
      <Skeleton height="5" width="75%" />
    )}
    {memberSince ? (
      <Text fontWeight="bold" fontSize={{ base: 'xs', md: 'sm' }}>
        Member Since:{' '}
        <Text fontWeight="normal" as="span">
          {memberSince}
        </Text>
      </Text>
    ) : (
      <Skeleton height="4" width="60%" />
    )}
  </Stack>
);

export default MemberInfo;
