import { Stack, Text } from '@chakra-ui/react';
import LDLink from 'components/common/LDLink';
import LDThemeButton from 'components/common/LDThemeButton';
import React from 'react';

export interface ButtonGroupProps {
  buttonsCollection: {
    items: Array<{ buttonColor: string; buttonText: string; buttonTextLink: string }>;
  };
}

export const ButtonGroup = ({ buttonsCollection }: ButtonGroupProps): JSX.Element | null => {
  if (!buttonsCollection?.items?.length) {
    return null;
  }
  return (
    <Stack direction={{ base: 'column', md: 'row' }} justify="center" align="center">
      {buttonsCollection?.items?.map(({ buttonColor, buttonText, buttonTextLink }, idx) => (
        <LDLink href={buttonTextLink} key={`button-${idx}`}>
          <LDThemeButton minWidth="200px" bgColor={buttonColor}>
            {buttonText}
          </LDThemeButton>
        </LDLink>
      ))}
    </Stack>
  );
};
