// for sending a date to Kobie endpoints. They only accept it in this Java-compatible format
export const getFormattedDate = (date: Date, isOffer = false): string => {
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();
  return `${year}-${month < 10 ? `0${month}` : month}-${day < 10 ? `0${day}` : day}T0${
    isOffer ? '5:01' : '0:00'
  }:00.000+0000`;
  // const year = date.getFullYear();
  // const month = String(date.getUTCMonth()+1).padStart(2,'0');
  // const day = String(date.getUTCDate()).padStart(2,'0');
  // const hours = String(date.getUTCHours()).padStart(2,'0');
  // const minutes = String(date.getUTCMinutes()).padStart(2,'0');
  // const seconds = String(date.getUTCSeconds()).padStart(2,'0');
  // const milliseconds = String(date.getUTCMilliseconds()).padStart(3,'0');
  // return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}+0000`
};
