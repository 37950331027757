import { Box, Heading, Text } from '@chakra-ui/react';
import React from 'react';
import { presetComponentProps } from 'theme';

const RewardsAndVoucherMessage = ({ balance }: { balance: number }): JSX.Element => {
  return (
    <Box pr={{ md: '1.5rem' }}>
      <Heading {...presetComponentProps?.h2} fontWeight="normal" color="primary.default">
        {balance < 250 ? 'Keep collecting points to unlock a reward' : 'Get your next reward!'}
      </Heading>
      <Text>
        {balance < 250 ? (
          'You don’t have enough points to exchange for a reward yet.'
        ) : (
          <>
            <Box as="strong">You've unlocked some rewards!</Box> Check them out below and exchange
            your points for the reward you want, or save your points to unlock a bigger reward.
          </>
        )}
      </Text>
    </Box>
  );
};

export default RewardsAndVoucherMessage;
