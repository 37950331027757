import {
  SimpleGrid,
  Stack,
  Text,
  Box,
  Flex,
  Button,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  Heading
} from '@chakra-ui/react';
import { ReactElement } from 'react';

import { CONTENT_TYPES } from 'utils/constants';
import { FaqItem } from 'components/CMS';
import { FaqItemCMSData, ListCMSData, LoggedInHomeBannerCMSData } from 'types/cms/fragments';
import LoggedInHomeBanner from './banners/LoggedInHomeBanner';
import RewardTierTable from 'components/CMS/RewardTierTable';
import ProgramFeatureWithImage, { ProgramFeatureWithImageProps } from './ProgramFeatureWithImage';
import ProgramFeatureWithIcon, { ProgramFeatureWithIconProps } from './ProgramFeatureWithIcon';
import FeaturedProductsCarousel from './FeaturedProductsCarousel';
import useFaqData from 'hooks/useFaqData';
import { AddIcon, MinusIcon } from '@chakra-ui/icons';
import { presetComponentProps } from 'theme';

export interface ListGeneratorProps extends ListCMSData {}

type ListType =
  | CONTENT_TYPES.FAQ_ITEM
  | CONTENT_TYPES.PROGRAM_BENEFIT_ITEM
  | CONTENT_TYPES.PROGRAM_BENEFIT_ITEM
  | CONTENT_TYPES.PROGRAM_FEATURE_WITH_ICON
  | CONTENT_TYPES.PROGRAM_FEATURE_WITH_IMAGE
  | CONTENT_TYPES.LOGGED_IN_HOME_BANNER
  | string;

const ListGenerator = ({ itemCollection, ...props }: ListGeneratorProps): ReactElement | null => {
  const { currentFaqListKey, setCurrentFaqList, tabNames, faqData } = useFaqData({
    listData: itemCollection?.items
  });
  const listType: ListType = itemCollection?.items?.[0]?.__typename;
  const gridColumns = itemCollection?.items?.length;
  // Used for ProgramFeatureWithImage component to know layout pattern of Images depending on if its shown on Desktop or Mobile.
  let isRightToLeft = true;

  switch (listType) {
    case CONTENT_TYPES.FAQ_ITEM:
      return (
        <Flex flexDir="column" justifyContent="center" width="100%">
          <Flex
            justifyContent="center"
            display={{ base: 'none', md: 'flex' }}
            mb="1.5rem"
            borderBottom="1px solid"
            borderColor="shades.250"
          >
            {tabNames?.map((tabName, index) => (
              <Box
                key={tabName}
                borderBottom={
                  tabName === faqData?.[`${currentFaqListKey}`]?.tabName
                    ? '4px solid #005dab'
                    : 'none'
                }
                mr={tabNames?.length - 1 !== index ? { base: '10', lg: '20' } : '0'}
                _focus={{ boxShadow: 'none !important' }}
              >
                <Button
                  variant="ghost"
                  backgroundColor="white"
                  padding="3"
                  width="100%"
                  height="100%"
                  onClick={() => {
                    setCurrentFaqList(tabName);
                  }}
                  paddingY="6"
                >
                  <Text
                    as="span"
                    fontSize="xl"
                    color={
                      tabName === faqData?.[`${currentFaqListKey}`]?.tabName
                        ? 'primary.default'
                        : '#818181'
                    }
                  >
                    {tabName}
                  </Text>
                </Button>
              </Box>
            ))}
          </Flex>
          <Flex
            justifyContent={{ base: 'center', md: 'flex-start' }}
            mb="0.3rem"
            w="100%"
            maxW="861px"
            mx="auto"
          >
            <Heading
              {...presetComponentProps?.h2}
              color={props.headlineColor ?? 'primary.default'}
              fontWeight="normal"
            >
              {props?.headline ?? 'Top FAQs'}
            </Heading>
          </Flex>

          <Accordion allowToggle allowMultiple={false} display={{ base: 'none', md: 'flex' }}>
            <Stack spacing="2.5" mb="1rem" w="100%" maxW="861px" mx="auto">
              {faqData?.[`${currentFaqListKey}`]?.faqItems?.map((faqItem, index: number) => (
                <FaqItem
                  key={`faq-item-${index}`}
                  {...faqItem}
                  index={index}
                  question={faqItem.question}
                  answer={faqItem.answer}
                />
              ))}
            </Stack>
          </Accordion>

          <Accordion
            defaultIndex={[0]}
            allowToggle
            allowMultiple={false}
            display={{ base: 'block', md: 'none' }}
          >
            {Object.values(faqData)?.map(({ faqItems, tabName }, index) => (
              <AccordionItem
                key={tabName + '-accordion'}
                borderTopWidth="0"
                id={`faq-header-${index}`}
              >
                {({ isExpanded }) => (
                  <>
                    <AccordionButton
                      backgroundColor="blackAlpha.50"
                      py="4"
                      px="3"
                      border="solid 1px"
                      borderColor="shades.100"
                      marginBottom={Object.values(faqData)?.length - 1 === index ? 0 : 4}
                    >
                      <Flex justifyContent="space-between" alignItems="center" width="100%">
                        <Text
                          fontSize={{ base: 'md', md: 'lg' }}
                          color={isExpanded ? 'primary.default' : 'default'}
                          fontWeight="semibold"
                          lineHeight="8"
                          textAlign="left"
                        >
                          {tabName}
                        </Text>
                        {isExpanded ? (
                          <MinusIcon width="3.5" height="3.5" />
                        ) : (
                          <AddIcon width="3.5" height="3.5" />
                        )}
                      </Flex>
                    </AccordionButton>
                    <AccordionPanel p="1em 0">
                      <Accordion allowToggle allowMultiple={false}>
                        <Stack spacing="2.5">
                          {faqItems?.map((_item: FaqItemCMSData, _index: number) => (
                            <FaqItem key={`faq-item-${_index}`} {..._item} index={_index} />
                          ))}
                        </Stack>
                      </Accordion>
                    </AccordionPanel>
                  </>
                )}
              </AccordionItem>
            ))}
          </Accordion>
        </Flex>
      );
    case CONTENT_TYPES.PROGRAM_BENEFIT_ITEM:
      return <RewardTierTable content={{ ...props, itemCollection }} />;
    case CONTENT_TYPES.PROGRAM_FEATURE_WITH_ICON:
      return (
        <>
          <Box d="flex" flexWrap="wrap" justifyContent="center" maxW="1300px" mx="auto">
            {(itemCollection?.items as ProgramFeatureWithIconProps[]).map((iconData) => {
              return (
                <Box
                  key={iconData.title}
                  w={{ base: '100%', sm: '48%', md: '32%' }}
                  h={{ base: '80px', sm: '120px' }}
                  mb={{ base: '10px', sm: '20px', md: '20px' }}
                  px="4px"
                >
                  <ProgramFeatureWithIcon {...iconData} />
                </Box>
              );
            })}
            {props?.disclaimerSmallText && (
              <Box d="flex" width="100%" justifyContent={{ base: 'center', md: 'right' }}>
                <Text
                  textColor="#979798"
                  fontSize="14px"
                  textAlign={{ base: 'center', sm: 'center', md: 'right' }}
                  px={{ base: '20px', sm: '10px' }}
                  mr={{ md: '0.5rem', lg: '0.8rem' }}
                >
                  {props.disclaimerSmallText}
                </Text>
              </Box>
            )}
          </Box>
        </>
      );
    case CONTENT_TYPES.PROGRAM_FEATURE_WITH_IMAGE:
      return (
        <SimpleGrid columns={{ base: 1, sm: 2, md: 2 }} maxW="1300px" mx="auto">
          {(itemCollection?.items as ProgramFeatureWithImageProps[]).map((imageData, index) => {
            if (index % 2 === 0) {
              isRightToLeft = !isRightToLeft;
            }
            const isFinalOddItem =
              index + 1 === (itemCollection?.items as ProgramFeatureWithImageProps[]).length &&
              index % 2 === 0;
            return (
              <ProgramFeatureWithImage
                {...imageData}
                isRightToLeft={isRightToLeft}
                isFinalOddItem={isFinalOddItem}
                key={imageData.title}
                index={index}
              />
            );
          })}
        </SimpleGrid>
      );
    case CONTENT_TYPES.LOGGED_IN_HOME_BANNER:
      return (
        <SimpleGrid
          columns={{ base: 1, md: gridColumns > 2 ? 2 : gridColumns, lg: gridColumns }}
          spacing={{ base: '4', lg: '5' }}
          width="100%"
        >
          {(itemCollection?.items as LoggedInHomeBannerCMSData[])?.map((item, index) => (
            <LoggedInHomeBanner key={`logged-in-home-banner-${index}`} {...item} index={index} />
          ))}
        </SimpleGrid>
      );
    case CONTENT_TYPES.EXAMPLE_PRODUCT_ITEM:
      return <FeaturedProductsCarousel cmsData={itemCollection?.items} />;
    default:
      return null;
  }
};

export default ListGenerator;
