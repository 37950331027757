import { Box } from '@chakra-ui/react';
import { load } from 'cheerio';

export const isD365FormValid = async (form: any) => {
  const { orgId, formId } = form;
  const finalOrgId = orgId ?? process.env.CONTENTFUL_ORG_ID;
  const formResponse = await fetch(
    `https://assets-can.mkt.dynamics.com/${finalOrgId}/digitalassets/forms/${formId}`
  );
  return formResponse?.statusText == 'OK';
};

export const getFormattedEmbedVideo = (props: any) => {
  let str;
  const videoContainsIframe = props?.customEmbed?.json?.content.filter((item:any)=>item?.content?.[0]?.value?.includes("iframe"))?.[0];
  if (videoContainsIframe){
    str = videoContainsIframe?.content?.[0]?.value;
  }else{
    str = props?.customEmbed?.json?.content?.[0]?.content?.[0]?.value;
  }
  let src;
  if (str) {
    if (!str.includes('iframe')) {
      if (isYouTubeUrl(str)) {
        const videoId = extractYouTubeId(str);
        src = `https://www.youtube-nocookie.com/embed/${videoId}`;
      } else if (isVimeoUrl(str)) {
        const videoId = extractVimeoId(str);
        src = `https://player.vimeo.com/video/${videoId}`;
      } else {
        return '';
      }
      str = `<iframe src="${src}"></iframe>`;
    }

    const $ = load(str);
    const iframe = $('iframe');
    let iframeSrc = iframe.attr('src');
    
    const allowedSources = [
      'https://www.youtube.com/embed/',
      'https://www.youtube-nocookie.com/embed/',
      'https://player.vimeo.com/',
      'https://vimeo.com/'
    ];

    const isValidSrc = allowedSources.some((source) => iframeSrc?.startsWith(source));
    if (!isValidSrc) {
      iframe.remove();
    } else {
      if (iframeSrc?.startsWith('http://')) {
        iframe.attr('src', iframeSrc.replace('http://', 'https://'));
      }
      if (iframeSrc?.includes('vimeo') && !iframeSrc?.includes('player')){
        iframeSrc = iframeSrc.replace('vimeo.com/', 'player.vimeo.com/video/')
      }
      iframe.removeAttr('allowfullscreen');
      iframe.attr('referrerpolicy', 'no-referrer');
      iframe.attr('allow', 'accelerometer; encrypted-media; gyroscope; picture-in-picture');
      iframe.attr('sandbox', 'allow-scripts allow-same-origin');
    }
    const attributes = iframe.attr();
    return (
      <iframe
        src={iframeSrc}
        height={attributes?.height ?? "400" }
        width={attributes?.width ?? "100%"}
        allowFullScreen={false}
        referrerPolicy='no-referrer'
        allow='accelerometer; encrypted-media; gyroscope; picture-in-picture'
        sandbox='allow-scripts allow-same-origin'
      />
    );
  }

  return null;
};


function isYouTubeUrl(url:string) {
  return /(?:youtube\.com|youtu\.be)/.test(url);
}

// Helper function to check if URL is a Vimeo link
function isVimeoUrl(url:string) {
  return /vimeo\.com/.test(url);
}

function extractYouTubeId(url:string) {
  const match = url.match(/(?:https?:\/\/(?:www\.)?youtube\.com\/(?:watch\?v=|embed\/|v\/|.+\?v=)|youtu\.be\/)([^&?/]{11})/);
  return match ? match[1] : url; // If it's an ID, just return it
}

// Helper function to extract Vimeo ID from URL or ID
function extractVimeoId(url:string) {
  const match = url.match(/(?:https?:\/\/(?:www\.)?vimeo\.com\/(?:channels\/(?:\w+\/)?|groups\/(?:\w+\/)?|album\/(?:\d+\/)?video\/|video\/|)(\d+)(?:$|\/|\?))/);
  return match ? match[1] : url; // If it's an ID, just return it
}