import { Box } from '@chakra-ui/layout';
import React, { ReactElement } from 'react';
import LDLink from 'components/common/LDLink';

import ProxiedImage from 'components/common/ProxiedImage';
import { ImageBannerCMSData } from 'types/cms/fragments';

export interface ImageBannerProps extends ImageBannerCMSData {}

const InnerImageBanner = ({ image, mobileImage }: Omit<ImageBannerProps, 'linkUrl' | 'title'>) => {
  return (
    <Box w="100%">
      {mobileImage && (
        <Box d={{ base: 'block', md: 'none' }}>
          <ProxiedImage
            src={mobileImage?.url}
            alt={mobileImage?.description ?? undefined}
            width={mobileImage?.width}
            height={mobileImage?.height}
            layout="responsive"
          />
        </Box>
      )}
      <Box
        d={mobileImage ? { base: 'none', md: 'block' } : 'block'}
        mx={'auto'}
        position="relative"
        left="0"
        right="0"
        w="100%"
        maxW={1300}
      >
        <ProxiedImage
          src={image?.url}
          alt={image?.description ?? undefined}
          width={image?.width}
          height={image?.height}
          layout="responsive"
        />
      </Box>
    </Box>
  );
};

const ImageBanner = ({ title, linkUrl, ...imageBannerProps }: ImageBannerProps): ReactElement => {
  return (
    <Box key={title} width="100%">
      {linkUrl ? (
        <LDLink href={linkUrl} aria-label={`${title} (Banner)`}>
          <InnerImageBanner {...{ ...imageBannerProps }} />
        </LDLink>
      ) : (
        <InnerImageBanner {...{ ...imageBannerProps }} />
      )}
    </Box>
  );
};

export default ImageBanner;
