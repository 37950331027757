import { Image, Box, Stack } from '@chakra-ui/react';
import React from 'react';
import { ImageInTextCMSDate } from 'types/cms/fragments';

export interface ImageInTextProps extends ImageInTextCMSDate {}

const ImageInText = ({ image, imageWidth, caption }: ImageInTextProps): JSX.Element => {
  return (
    <Stack d="flex" flexDir="column" justifyContent="center" spacing={4} mb="2rem !important">
      <Image src={image?.url} alt={image?.description} width={imageWidth} />
      <Box as="caption" color="shades.300" mt="2rem">
        {caption}
      </Box>
    </Stack>
  );
};

export default ImageInText;
