import React, { ComponentProps, ReactElement, ReactNode } from 'react';
import { default as NextLink } from 'next/link';
import { Link } from '@chakra-ui/layout';

export interface LDLinkProps extends Omit<ComponentProps<typeof Link>, 'href' | 'children'> {
  linkText?: string;
  href: string;
  children?: JSX.Element | ReactNode;
}

const LDLink = ({
  href,
  children,
  linkText,
  variant,
  ...rest
}: LDLinkProps): ReactElement => {
  const isExternalTest = (href: string) => {
    if (href?.startsWith('/')) return false;

    return true;
  };

  const child = () => {
    if (linkText) return linkText;

    if (children) return children;
  };

  return isExternalTest(href) ? (
    <Link
      {...rest}
      href={href}
      cursor="pointer"
      variant={variant}
      _hover={
        !variant ? rest?._hover ?? { color: 'shades.400', textDecoration: 'none' } : undefined
      }
    >
      {child()}
    </Link>
  ) : (
    <NextLink href={href} passHref>
      <Link
        cursor="pointer"
        variant={variant}
        _hover={
          !variant ? rest?._hover ?? { color: 'shades.400', textDecoration: 'none' } : undefined
        }
        {...rest}
      >
        {child()}
      </Link>
    </NextLink>
  );
};

export default LDLink;
