import { CONTENT_TYPES } from 'utils/constants';
import { IMAGE_BANNER, FEATURE_SPLIT, IMAGE_BANNER_WITH_TEXT_OVERLAY } from '.';
import { LIST, TEXT } from './common';

export const HOME_MAIN_HERO_LEFT = `
fragment ${CONTENT_TYPES.HOME_MAIN_HERO_LEFT}Fragment on ${CONTENT_TYPES.HOME_MAIN_HERO_LEFT} {
  title
  headline
  headlineColor
  text
  textColor
  backgroundColor
  buttonColor
  backgroundIconsOrImage {
    ...Image
  }
  button1Text
  button1Link
  button1Icon {
    ...Image
  }
  button2Text
  button2Link
  button2Icon {
    ...Image
  }
  showButtonsAsTextLinks
}
`;

export const HOME_SUB_FEATURE_RIGHT = `
fragment ${CONTENT_TYPES.HOME_SUB_FEATURE_RIGHT}Fragment on ${CONTENT_TYPES.HOME_SUB_FEATURE_RIGHT} {
  title
  icon {
    ...Image
  }
  iconBackgroundColor
  headline
  headlineColor
  text
  textColor
  ctaText
  ctaUrl
}
`;

export const HOME_HERO_BANNER = `
fragment ${CONTENT_TYPES.HOME_HERO_BANNER}Fragment on ${CONTENT_TYPES.HOME_HERO_BANNER} {
  title
  text
  textColor
  backgroundColor
  button1Text
  button1Link
  button1Icon {
    ...Image
  }
  button2Text
  button2Link
  button2Icon {
    ...Image
  }
}  
`;

export const HOME_HERO_SINGLE_IMAGE = `
fragment ${CONTENT_TYPES.HOME_HERO_SINGLE_IMAGE}Fragment on ${CONTENT_TYPES.HOME_HERO_SINGLE_IMAGE} {
  __typename
  desktopImage {
    ...Image
  }
  tabletImage {
    ...Image
  }
  mobileImage {
    ...Image
  }
  imageAltText
  imageLink
}
`;

export const HOME_HERO = `
${HOME_MAIN_HERO_LEFT}
${HOME_SUB_FEATURE_RIGHT}
fragment ${CONTENT_TYPES.HOME_HERO}Fragment on ${CONTENT_TYPES.HOME_HERO} {
  title
  __typename
  mainHeroLeft {
    ...${CONTENT_TYPES.HOME_MAIN_HERO_LEFT}Fragment
  }
  subFeaturesRightImage {
    ...Image
  }
  subFeaturesRightCollection {
    items {
      __typename
      ... on ${CONTENT_TYPES.HOME_SUB_FEATURE_RIGHT} {
        ...${CONTENT_TYPES.HOME_SUB_FEATURE_RIGHT}Fragment
      }
    }
  }
}
`;

export const HOME_PAGE_BODY_COLLECTION = `
${IMAGE_BANNER}
${FEATURE_SPLIT}
${LIST}
${IMAGE_BANNER_WITH_TEXT_OVERLAY}
${TEXT}
fragment ${CONTENT_TYPES.HOME_PAGE_BODY_COLLECTION}Fragment on ${CONTENT_TYPES.HOME_PAGE_BODY_COLLECTION} {
  items {
    __typename
    ...on ${CONTENT_TYPES.LIST} {
      ...${CONTENT_TYPES.LIST}Fragment
    }
    ...on ${CONTENT_TYPES.IMAGE_BANNER} {
      ...${CONTENT_TYPES.IMAGE_BANNER}Fragment
    }
    ...on ${CONTENT_TYPES.FEATURE_SPLIT} {
      ...${CONTENT_TYPES.FEATURE_SPLIT}Fragment
    }
    ...on ${CONTENT_TYPES.IMAGE_BANNER_WITH_TEXT_OVERLAY} {
      ...${CONTENT_TYPES.IMAGE_BANNER_WITH_TEXT_OVERLAY}Fragment
    }
    ...on ${CONTENT_TYPES.TEXT} {
      ...${CONTENT_TYPES.TEXT}Fragment
    }
  }  
}
`;
