import { TriangleUpIcon } from '@chakra-ui/icons';
import { Box, Flex, Skeleton, Stack, Text } from '@chakra-ui/react';
import { observer } from 'mobx-react';
import { ReactElement } from 'react';
import ProxiedImage from 'components/common/ProxiedImage';
import { EXTRAS_TIER_CODE } from 'utils/constants';
import { useStore } from 'store';

interface TierProgressProps {
  tierName: string;
  tierCode: 'TIER1' | 'TIER2';
}

const TierProgress = ({ tierName, tierCode }: TierProgressProps): ReactElement | null => {
  const { extrasStore } = useStore();
  const { amountSpentTowardsNextTier, remainingSpendRequirement } =
    extrasStore?.nextTierRequirements();

  const { amountSpentTowardsRequalification, remainingSpendRequalificationRequirement } =
    extrasStore?.tierRequalificationRequirements();

  if (tierCode === EXTRAS_TIER_CODE.TIER2) {
    if (extrasStore.isRequalified) {
      return (
        <Stack direction="row" alignItems="center" spacing={0}>
          <Box height={{ base: '30px', md: '40px' }} width={{ base: '30px', md: '40px' }}>
            <ProxiedImage
              src="/assets/icons/icon-achievement-blue.svg"
              width="100%"
              height="100%"
              alt="achievement"
            />
          </Box>
          <Text fontSize={{ base: 'md', md: 'lg' }} fontWeight="bold" color="primary.default">
            Congrats!{' '}
            <Text as="span" fontWeight="normal">
              You're a Fave
            </Text>
          </Text>
        </Stack>
      );
    }
    return (
      <Stack spacing={{ base: '0.5', md: '2' }}>
        <Flex justifyContent="flex-end" alignItems="center">
          <Box>
            {typeof remainingSpendRequalificationRequirement === 'number' ? (
              <Text fontSize="xs" color="primary.hover" fontWeight="semibold">
                ${Number(remainingSpendRequalificationRequirement).toLocaleString()} to maintain
                Fave status
              </Text>
            ) : (
              <Skeleton height="3" />
            )}
          </Box>
        </Flex>
        {typeof amountSpentTowardsRequalification === 'number' ? (
          <Flex position="relative">
            <Box
              borderRadius="base"
              bg="extras.olive"
              height="2"
              width={`${Math.floor(amountSpentTowardsRequalification / 10)}%`}
              zIndex="1"
              position="relative"
            >
              <Stack
                position="absolute"
                right={amountSpentTowardsRequalification >= 500 ? '-6px' : '-50px'}
                top="7px"
                spacing="-0.5"
                alignItems={amountSpentTowardsRequalification >= 500 ? 'flex-end' : 'flex-start'}
              >
                <TriangleUpIcon width="4" height="4" color="extras.olive" />
                <Text fontSize="xs" color="primary.hover" fontWeight="semibold">
                  ${Number(amountSpentTowardsRequalification).toLocaleString()} Spent
                </Text>
              </Stack>
            </Box>
            <Box
              borderRadius="base"
              bg="primary.default"
              height="2"
              width="100%"
              position="absolute"
              right="0"
              zIndex="0"
            />
          </Flex>
        ) : (
          <Skeleton height="2" />
        )}
      </Stack>
    );
  }
  return (
    <Stack spacing={{ base: '0.5', md: '2' }}>
      <Flex justifyContent="space-between" alignItems="center">
        <Box>
          {tierName ? (
            <Text fontSize="xs" color="primary.hover" fontWeight="semibold">
              {tierName}
            </Text>
          ) : (
            <Skeleton height="3" />
          )}
        </Box>
        <Box>
          {typeof remainingSpendRequirement === 'number' ? (
            <Text fontSize="xs" color="primary.hover" fontWeight="semibold">
              ${Number(remainingSpendRequirement).toLocaleString()} to Fave
            </Text>
          ) : (
            <Skeleton height="3" />
          )}
        </Box>
      </Flex>
      {typeof remainingSpendRequirement === 'number' ? (
        <Flex position="relative">
          <Box
            borderRadius="base"
            bg="extras.olive"
            height="2"
            width={`${Math.floor(amountSpentTowardsNextTier / 10)}%`}
            zIndex="1"
            position="relative"
          >
            <Stack
              position="absolute"
              right={amountSpentTowardsNextTier >= 500 ? '-6px' : '-50px'}
              top="7px"
              spacing="-0.5"
              alignItems={amountSpentTowardsNextTier >= 500 ? 'flex-end' : 'flex-start'}
            >
              <TriangleUpIcon width="4" height="4" color="extras.olive" />
              <Text fontSize="xs" color="primary.hover" fontWeight="semibold">
                ${Number(amountSpentTowardsNextTier).toLocaleString()} Spent
              </Text>
            </Stack>
          </Box>
          <Box
            borderRadius="base"
            bg="primary.default"
            height="2"
            width="100%"
            position="absolute"
            right="0"
            zIndex="0"
          />
        </Flex>
      ) : (
        <Skeleton height="2" />
      )}
    </Stack>
  );
};

export default observer(TierProgress);
