import { Button } from '@chakra-ui/button';
import { useTheme } from '@chakra-ui/system';
import React, { ComponentProps, forwardRef, LegacyRef } from 'react';
import { LightenDarkenColor } from 'utils';

export interface LDThemeButtonProps
  extends Omit<ComponentProps<typeof Button>, 'textColor' | 'backgroundColor'> {
  textColorOverride?: Pick<ComponentProps<typeof Button>, 'textColor'>;
}

const LDThemeButton = forwardRef(
  (
    { children, bgColor, fontWeight, variant, textColorOverride, ...rest }: LDThemeButtonProps,
    ref: LegacyRef<HTMLButtonElement>
  ) => {
    const theme = useTheme();
    const innerBgColor: string =
      bgColor === 'extras.green'
        ? theme.colors.extras.green
        : bgColor
        ? bgColor
        : theme.colors.primary.default;

    const autoTextColor = () => {
      if (bgColor === theme.colors.primary.default) return '#fff';

      if (bgColor === theme.colors.primary.dark) return '#fff';

      if (bgColor === theme.colors.shades[50]) return theme.colors.primary.dark;

      if (bgColor === '#fff') return theme.colors.primary.dark;

      return '#fff';
    };

    return (
      <Button
        {...rest}
        variant={variant}
        ref={ref}
        bgColor={variant !== 'link' ? innerBgColor : 'transparent !important'}
        fontWeight={fontWeight ?? 500}
        textDecor={variant === 'link' ? 'underline' : undefined}
        textColor={textColorOverride ?? (variant !== 'link' ? autoTextColor() : 'primary.default')}
        _hover={{
          bgColor: LightenDarkenColor(innerBgColor, -10),
          textColor:
            variant !== 'link'
              ? autoTextColor()
              : LightenDarkenColor(theme?.colors?.primary?.default, 20)
        }}
        minH="48px"
      >
        {children}
      </Button>
    );
  }
);

export default LDThemeButton;
