import { NAV_LINK_ITEM, SUB_LINK_COLLECTION_ITEM } from 'graphql/fragments';
import { CONTENT_TYPES } from 'utils/constants';

export const globalHeaderBannerQuery = `
{
globalHeaderBanner(id: "U9sIAjkdhYRgiAGPcR8rK") {
    title
    bannerText
    bannerBackgroundColor
    linkUrl
    linkText
    textColor
  }
}
`;

export const mainNavigationQuery = `
${NAV_LINK_ITEM}
{
  list(id: "2HxxbJgduFSuJcGePS2f1G") {
    title
    itemCollection {
      items {
        ...on ${CONTENT_TYPES.NAV_LINK_ITEM} {
          ...NavLinkItemFragment
        }
      }
    }
  }
}
`;

export const footerNavigationQuery = `
${NAV_LINK_ITEM}
{
  list(id: "7xWKQYzwMHeo2MJjuB9ot7") {
    title
    itemCollection {
      items {
        ...on ${CONTENT_TYPES.NAV_LINK_ITEM} {
          ...NavLinkItemFragment
        }
      }
    }
  }
}
`;

export const footerComplianceNavigationQuery = `
${SUB_LINK_COLLECTION_ITEM}
{
  navCategoryItem(id: "17nzCVIpw5AsQMn4Jxay3R") {
    title
    subcategoriesCollection {
      ...subLinkCollectionItemFragment
    }
  }
}
`;
