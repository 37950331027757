import { Box, Text } from '@chakra-ui/layout';
import React, { ReactElement } from 'react';
import LDLink from 'components/common/LDLink';
import { ProgramFeatureWithIconCMSData } from 'types/cms/fragments';
import ProxiedImage from 'components/common/ProxiedImage';

export interface ProgramFeatureWithIconProps extends ProgramFeatureWithIconCMSData {}

const SingleProgramWithIcon = ({
  icon,
  iconBackgroundColor,
  textColor,
  text,
  linkUrl
}: Omit<ProgramFeatureWithIconProps, '__typename'>) => {
  return (
    <Box
      width="100%"
      borderWidth="1px"
      _hover={linkUrl ? { background: 'shades.50' } : undefined}
      d="flex"
      alignItems="center"
      height={{ base: '80px', sm: '112px', md: '120px' }}
    >
      <Box
        bg={iconBackgroundColor}
        borderRadius="50%"
        width={{ base: '50px', sm: '65px', lg: '79px' }}
        height={{ base: '50px', sm: '65px', lg: '79px' }}
        d="flex"
        flex="none"
        mx={{ base: '3', lg: '5' }}
        alignItems="center"
        justifyContent="center"
      >
        <Box
          width={{ base: '35px', sm: '50px', md: '50px' }}
          height={{ base: '35px', sm: '50px', md: '50px' }}
        >
          <ProxiedImage
            src={icon?.url}
            alt={icon?.description ?? undefined}
            width="100%"
            height="100%"
          />
        </Box>
      </Box>
      <Box d="flex" pr="2">
        <Text color={textColor} fontSize={{ base: 'md', md: 'md', lg: 'lg', xl: 'xl' }}>
          {text}
        </Text>
      </Box>
    </Box>
  );
};

const ProgramFeatureWithIcon = ({
  title,
  linkUrl,
  ...programFeatureWithIconProps
}: ProgramFeatureWithIconProps): ReactElement => {
  return (
    <Box key={title}>
      {linkUrl ? (
        <LDLink href={linkUrl} aria-label={`${title} (Banner)`}>
          <SingleProgramWithIcon {...programFeatureWithIconProps} />
        </LDLink>
      ) : (
        <SingleProgramWithIcon {...{ ...programFeatureWithIconProps, linkUrl }} />
      )}
    </Box>
  );
};

export default ProgramFeatureWithIcon;
