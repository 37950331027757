import { Box, Text, Heading, Stack, Divider } from '@chakra-ui/layout';
import React, { ReactElement } from 'react';

import LDThemeButton from 'components/common/LDThemeButton';
import { ImageBannerWithTextOverlayCMSData } from 'types/cms/fragments';
import { hexToRgbA } from 'utils';
import { useTheme } from '@chakra-ui/react';
import LDLink from 'components/common/LDLink';

export interface ImageBannerTextOverlayProps extends ImageBannerWithTextOverlayCMSData {}

const ImageBannerTextOverlay = ({
  headline,
  headlineColor,
  subhead,
  buttonText,
  buttonTextLink,
  overlayBackgroundTrimColor,
  subheadColor,
  ...props
}: ImageBannerTextOverlayProps): ReactElement => {
  const theme = useTheme();
  const desktopImg = props?.imageCollection?.items?.[0];
  const mobileImg = props?.mobileImageCollection?.items?.[0];

  return (
    <Box
      width="100%"
      height={{ base: '480px', md: '508px' }}
      backgroundImage={
        mobileImg?.url
          ? {
              base: `url('${mobileImg?.url}')`,
              md: `url('${desktopImg?.url}')`
            }
          : `url('${desktopImg?.url}')`
      }
      backgroundPosition="center"
      backgroundSize="cover"
      backgroundRepeat="no-repeat"
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <Stack
        spacing={6}
        alignItems="center"
        textAlign="center"
        px="30px"
        py={{ base: '40px', md: '60px' }}
        maxW={{ base: '260px', md: '300px' }}
        background="white"
        backgroundClip="padding-box"
        border={`solid 20px ${hexToRgbA(
          overlayBackgroundTrimColor.includes('#')
            ? overlayBackgroundTrimColor
            : theme.colors.extras.olive,
          0.6
        )}`}
      >
        <Heading
          fontSize={{ base: 'xl', md: '3xl' }}
          fontWeight={{ base: 'medium', md: 'light' }}
          textColor={headlineColor ?? 'primary.default'}
        >
          {headline}
        </Heading>
        <Divider />
        {subhead && <Text color={subheadColor ?? 'black'}>{subhead}</Text>}
        {buttonTextLink && buttonText && (
          <LDLink href={buttonTextLink}>
            <LDThemeButton as="a">{buttonText}</LDThemeButton>
          </LDLink>
        )}
      </Stack>
    </Box>
  );
};

export default ImageBannerTextOverlay;
