import { Flex, Text } from '@chakra-ui/react';
import LDLink from 'components/common/LDLink';
import { ReactElement } from 'react';

const PreviewBanner = (): ReactElement => {
  return (
    <Flex
      backgroundColor="status.alert"
      position="fixed"
      bottom="0"
      left="0"
      right="0"
      zIndex="999"
      justifyContent="space-between"
      alignItems="center"
      paddingX="5"
    >
      <Text color="white" fontWeight="bold" fontSize="lg">
        Preview Mode Enabled
      </Text>
      <LDLink
        href="/api/exit-preview"
        color="white"
        fontWeight="bold"
        textDecoration="none"
        fontSize="lg"
        as="a"
        _hover={{ color: 'shades.300' }}
      >
        Exit Preview
      </LDLink>
    </Flex>
  );
};

export default PreviewBanner;
