import { CONTENT_TYPES } from 'utils/constants';

export const SUB_LINK_COLLECTION_ITEM = `
fragment subLinkCollectionItemFragment on ${CONTENT_TYPES.SUB_LINK_COLLECTION_ITEM} {
  items {
    title
    route
    textColor
  }
}
`;

export const NAV_LINK_ITEM = `
${SUB_LINK_COLLECTION_ITEM}
fragment NavLinkItemFragment on ${CONTENT_TYPES.NAV_LINK_ITEM} {
  title
  route
  subcategoriesCollection {
    ...subLinkCollectionItemFragment
  }
}               
`;
