import { Image } from '@chakra-ui/image';
import { Box, useMediaQuery } from '@chakra-ui/react';
import LDLink from 'components/common/LDLink';
import { HomePageQueryRes } from 'types/cms/homeFragments';
import HomeHeroBanner from '../banners/HomeHeroBanner';

export interface HomeHeroSingleImageProps extends HomePageQueryRes {}

const HomeHeroSingleImage = ({
  homeHero,
  homeHeroBanner
}: HomeHeroSingleImageProps): React.ReactElement => {
  return (
    <>
      <LDLink href={homeHero?.imageLink || ''}>
        <Image
          src={homeHero?.mobileImage?.url}
          d={['block', 'none']}
          alt={homeHero?.imageAltText}
          width="100%"
        />
        <Image
          src={homeHero?.tabletImage?.url}
          d={['none', 'block', 'none']}
          alt={homeHero?.imageAltText}
          width="100%"
        />
        <Image
          src={homeHero?.desktopImage?.url}
          d={['none', 'none', 'block']}
          alt={homeHero?.imageAltText}
          width="100%"
        />
      </LDLink>
      <Box mt="8px">
        <HomeHeroBanner {...homeHeroBanner} />
      </Box>
    </>
  );
};

export default HomeHeroSingleImage;
