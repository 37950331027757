import React, { ReactElement } from 'react';
import { Box, Flex, Heading, Stack, Text, useTheme } from '@chakra-ui/react';
import { Image } from '@chakra-ui/image';
import { observer } from 'mobx-react';

import LDThemeButton from 'components/common/LDThemeButton';
import { HomeHeroBannerCMSData, HomeHeroCMSData } from 'types/cms/homeFragments';
import { hexToRgbA } from 'utils';
import HomeHeroBanner from '../banners/HomeHeroBanner';
import LDLink from 'components/common/LDLink';

export interface HomeHeroProps {
  homeHero: HomeHeroCMSData;
  homeHeroBanner: HomeHeroBannerCMSData;
}

const HomeHero = ({ homeHero, homeHeroBanner }: HomeHeroProps): ReactElement => {
  const theme = useTheme();
  const mainHeroLeft = homeHero?.mainHeroLeft;
  const subFeaturesRightCollection = homeHero?.subFeaturesRightCollection;
  const subFeaturesRightImage = homeHero?.subFeaturesRightImage;

  return (
    <>
      <Box
        h={{
          base: 'auto',
          md: '22.5rem'
        }}
        w="100%"
        bgColor={mainHeroLeft?.backgroundColor}
        backgroundImage={`url(${mainHeroLeft?.backgroundIconsOrImage?.url})`}
        backgroundSize="216px"
      >
        <Box
          h={{ base: 'auto', md: '100%' }}
          d="flex"
          flexDir={{
            base: subFeaturesRightCollection?.items?.length >= 1 ? 'column' : 'column-reverse',
            md: 'row'
          }}
          justifyContent="space-between"
        >
          {/* Used for alignment on desktop */}
          <Box w="10%" d={{ base: 'none', '2xl': 'block' }} />
          <Box
            d="flex"
            h={{
              base: '264px',
              md: '100%'
            }}
            w={{ base: '100%', md: '62%', '2xl': '50%' }}
            justifyContent="center"
            alignItems="center"
            textAlign="center"
            px={{ base: '0.5rem', md: '1rem' }}
            py={{ base: '2rem', md: 0 }}
          >
            <Stack
              spacing={4}
              d="flex"
              justifyContent="center"
              alignItems="center"
              flexDir="column"
              mx="auto"
            >
              <Heading
                fontSize={{ base: '4xl', sm: '5xl' }}
                lineHeight="0.99999em"
                w="100%"
                maxWidth="600px"
                fontWeight="medium"
                fontStyle="italic"
                textShadow="0 0 8px rgba(0,0,0,0.3)"
                color={mainHeroLeft?.headlineColor ?? 'white'}
              >
                {mainHeroLeft?.headline}
              </Heading>
              {mainHeroLeft?.text && (
                <Text
                  w="100%"
                  maxWidth="504px"
                  lineHeight="1.375em"
                  fontWeight="regular"
                  color={mainHeroLeft?.textColor ?? 'primary.default'}
                  fontSize={{ base: 'sm', sm: 'md' }}
                >
                  {mainHeroLeft?.text}
                </Text>
              )}
              <Flex justifyContent="space-evenly" width="auto">
                {mainHeroLeft?.button1Link && (
                  <LDLink href={mainHeroLeft?.button1Link}>
                    <LDThemeButton
                      as="a"
                      fontWeight="medium"
                      bgColor={mainHeroLeft?.buttonColor ?? 'primary.default'}
                      fontSize={{ base: 'md', md: 'lg' }}
                      mr={mainHeroLeft?.button2Link ? '1.25rem' : undefined}
                      variant={mainHeroLeft?.showButtonsAsTextLinks ? 'link' : undefined}
                      leftIcon={
                        mainHeroLeft?.button1Icon?.url ? (
                          <Image
                            src={mainHeroLeft?.button1Icon?.url}
                            alt={mainHeroLeft?.button1Icon?.description}
                            sizes="icon"
                          />
                        ) : undefined
                      }
                    >
                      {mainHeroLeft?.button1Text}
                    </LDThemeButton>
                  </LDLink>
                )}{' '}
                {mainHeroLeft?.button2Link && (
                  <LDLink href={mainHeroLeft?.button2Link}>
                    <LDThemeButton
                      as="a"
                      bgColor={mainHeroLeft?.buttonColor ?? 'primary.default'}
                      fontWeight="medium"
                      fontSize={{ base: 'md', md: 'lg' }}
                      variant={mainHeroLeft?.showButtonsAsTextLinks ? 'link' : undefined}
                      leftIcon={
                        mainHeroLeft?.button2Icon?.url ? (
                          <Image
                            src={mainHeroLeft?.button2Icon?.url}
                            alt={mainHeroLeft?.button2Icon?.description}
                            sizes="icon"
                          />
                        ) : undefined
                      }
                    >
                      {mainHeroLeft?.button2Text}
                    </LDThemeButton>
                  </LDLink>
                )}
              </Flex>
            </Stack>
          </Box>
          <Box
            backgroundRepeat="no-repeat"
            backgroundPosition="top"
            backgroundSize="cover"
            bgColor={mainHeroLeft?.backgroundColor}
            backgroundImage={`url(${subFeaturesRightImage?.url})`}
            clipPath={{ md: 'polygon(64px 0%, 100% 0%, 100% 100%, 0% 100%)' }}
            h={{
              base: subFeaturesRightCollection?.items?.length >= 1 ? '269px' : '190px',
              sm: subFeaturesRightCollection?.items?.length >= 1 ? '335px' : '250px',
              md: 'auto'
            }}
            w={{ base: '100%', md: '70%', lg: '50%' }}
            role={subFeaturesRightImage?.description ? 'img' : undefined}
            aria-label={subFeaturesRightImage?.description}
            d={{ base: 'block' }}
            pos="relative"
          >
            {subFeaturesRightCollection?.items?.length >= 1 && (
              <Stack pos="absolute" bottom="44px" left="-8px" w="100%">
                {subFeaturesRightCollection?.items?.map((subFeature, index) => {
                  return (
                    <Box
                      key={subFeature?.title}
                      filter={`drop-shadow(.75em .75em 0px ${hexToRgbA(
                        subFeature?.headlineColor ?? theme?.colors?.primary?.default,
                        0.8
                      )})`}
                      mb={
                        subFeaturesRightCollection?.items?.length - 1 !== index ? '1rem' : undefined
                      }
                    >
                      <Box
                        d="flex"
                        alignItems="center"
                        flexDir="row"
                        bgColor="white"
                        ml={{ base: '-10px', md: '0' }}
                        width={{ base: '95%', '2xl': '550px' }}
                        height={{ base: '90px', sm: '120px', md: '130px' }}
                        clipPath="polygon(0% 0%, 100% 0%, calc(100% - 32px) 100%, 0% 100%);"
                        p={{
                          base: '1rem 1.5rem',
                          md: '1rem 1.2rem 1rem 1.2rem',
                          lg: '1rem 1.5rem'
                        }}
                      >
                        <Flex
                          flexDir="row"
                          pl={{ base: 2, md: 12, xl: 16 }}
                          alignItems="center"
                          justifyContent="flex-start"
                        >
                          <Image
                            d={{ base: 'none', sm: 'block' }}
                            borderRadius="50%"
                            p="0.35rem"
                            h="64px"
                            w="64px"
                            bgColor={subFeature?.iconBackgroundColor}
                            src={subFeature?.icon?.url}
                            alt={subFeature?.icon?.description ?? undefined}
                          />
                          <Stack spacing="1" px={{ base: '0', sm: '1rem' }}>
                            <Heading
                              fontSize={{ base: 'lg', md: 'lg' }}
                              color={subFeature?.headlineColor ?? 'primary.default'}
                            >
                              {subFeature?.headline}
                            </Heading>
                            <Text fontSize={{ base: 'xs', lg: 'sm' }}>
                              <Box as="span" mr={subFeature?.ctaUrl ? '0.3rem' : undefined}>
                                {subFeature?.text?.substring(0, 70)}
                              </Box>
                              {subFeature?.ctaUrl && (
                                <Box as="span" d="inline-flex">
                                  <LDLink
                                    href={subFeature?.ctaUrl ?? '/404'}
                                    fontWeight="medium"
                                    fontSize={{ base: 'xs', lg: 'sm' }}
                                    color="primary.default"
                                    whiteSpace="nowrap"
                                    textDecoration="none"
                                    _hover={{ bg: 'shades.100', textDecoration: 'none' }}
                                  >
                                    {subFeature?.ctaText}
                                  </LDLink>
                                </Box>
                              )}
                            </Text>
                          </Stack>
                        </Flex>
                      </Box>
                    </Box>
                  );
                })}
              </Stack>
            )}
          </Box>
        </Box>
      </Box>
      <Box mt="8px">
        <HomeHeroBanner {...homeHeroBanner} />
      </Box>
    </>
  );
};

export default observer(HomeHero);
