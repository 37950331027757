import { Box, Divider, Flex, Image, Stack, Text, Tooltip } from '@chakra-ui/react';
import LDLink from 'components/common/LDLink';
import React, { ReactElement } from 'react';
import { ExtrasPerkData } from 'types/cms/fragments';
import { GTMGeneralEvent } from 'utils/gtmHelpers';
import LoadOfferButton from './LoadOfferButton';

export interface FeaturedPerkProps extends ExtrasPerkData {
  useKobie?: boolean;
  offerLink?: string;
  isLoadableOffer?: boolean;
  expiresDate?: Date | null;
  startedDate?: Date | null;
  isRegistered?: boolean;
  updateLoadOffer?: (index: any) => void;
}

const FeaturedPerk = ({
  pointsAvailable,
  headline,
  expirationDate,
  image,
  useKobie,
  offerLink,
  offerCode,
  isLoadableOffer,
  startedDate,
  expiresDate,
  isRegistered,
  updateLoadOffer,
  accountOfferId
}: FeaturedPerkProps): ReactElement => {

  const text = useKobie
    ? headline
    : `Earn ${pointsAvailable} pts ${headline?.toLocaleLowerCase().replace(/^\S+\s/, '')}`;
  return (
    <Stack spacing={{ base: '3.5', md: '6' }}>
      <Box border="1px solid" borderColor="shades.100" borderRadius="md" p={{ base: 4, md: 6 }}>
        <Image
          src={image?.url}
          alt={image?.description}
          width="100%"
          height="100%"
          objectFit="contain"
        />
      </Box>
      <Stack alignItems="center">
        <Tooltip
          label={text}
          placement="bottom-start"
          bgColor="white"
          color="colors.default"
          border="1px solid #AFBAC3"
          width="271px"
          padding="15px 25px"
        >
          <Text
            fontSize={{ base: 'sm', lg: 'lg' }}
            fontWeight="medium"
            color="primary.default"
            textAlign="center"
            noOfLines={2}
          >
            {text}
          </Text>
        </Tooltip>
        {useKobie && expirationDate && (
          <Text fontSize={{ base: 'xs', lg: 'sm' }} color="extras.ltGray">
            {expirationDate}
          </Text>
        )}
      </Stack>
      {isLoadableOffer && (
        <Box textAlign="center">
          <LoadOfferButton //Added the button component to render if its loadable offer
            offerCode={offerCode}
            accountOfferId={accountOfferId}
            startedDate={startedDate}
            expiresDate={expiresDate}
            isRegistered={isRegistered}
            height={{ base: '40px', md: '48px' }}
            width={{ base: '133px', lg: '275px' }}
            updateLoadOffer={(offerId) => updateLoadOffer && updateLoadOffer(offerId)}
          />
        </Box>
      )}
      <Flex gridGap={2} align="center" justify="center">
        {offerLink && (
          <React.Fragment>
            <LDLink
              href={offerLink}
              target="_blank"
              rel="noopener noreferrer"
              onClick={() => GTMGeneralEvent({ event: 'shop_now', offer_name: text })}
              fontSize={{ base: 'xs', lg: 'sm' }}
              color="shades.300"
              textDecoration="underline"
              textAlign="center"
              _hover={{ color: 'shades.300' }}
            >
              Shop Now
            </LDLink>
            <Divider orientation="vertical" height={4} />
          </React.Fragment>
        )}
        {useKobie ? (
          <LDLink
            href={
              isLoadableOffer
                ? `/my-ldextras/loadable-offers-detail-page?offerCode=${offerCode}&offers=true`
                : `/my-ldextras/available-extras-detail-page?offerCode=${offerCode}&perks=true`
            }
            onClick={() => GTMGeneralEvent({ event: 'offer_details', offer_name: text })}
            fontSize={{ base: 'xs', lg: 'sm' }}
            color="shades.300"
            textDecoration="underline"
            textAlign="center"
            _hover={{ color: 'shades.300' }}
          >
            Offer Details
          </LDLink>
        ) : (
          <Text
            fontSize={{ base: 'xs', lg: 'sm' }}
            color="shades.300"
            textDecoration="underline"
            textAlign="center"
          >
            Certain restrictions apply.
          </Text>
        )}
      </Flex>
    </Stack>
  );
};

export default React.memo(FeaturedPerk);
