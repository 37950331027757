export enum URLS {
  ABOUT_EXTRAS = '/about-ldextras',
  MY_EXTRAS = '/my-ldextras',
  MY_EXTRAS_AVAILABLE_PERKS = '/my-ldextras/available-extras',
  MY_EXTRAS_REWARDS = '/my-ldextras/rewards-and-vouchers',
  MY_EXTRAS_ACCOUNT = '/my-ldextras/my-profile',
  SIGN_IN = '/sign-in',
  SIGN_IN_FIRST_TIME_STEP_1 = '/sign-in-first-time-step1',
  SIGN_IN_FIRST_TIME_STEP_2 = '/sign-in-first-time-step2',
  SIGN_IN_FIRST_TIME_STEP_3 = '/sign-in-first-time-step3',
  FORGOT_PASSWORD = '/forgot-password',
  RESET_PASSWORD = '/reset-password',
  SIGN_UP = '/sign-up-information',
  SIGN_UP_CONFIRMATION = '/sign-up-confirmation',
  PASSWORD_RESET = '/password-reset',
  POINTS_CLAIM_FORM = '/points-claim-form'
}

export enum CONTENT_TYPES {
  ASSET = 'Asset',
  TEXT = 'Text',
  FEATURE_SPLIT = 'FeatureSplitLeftright',
  IMAGE_BANNER = 'ImageBanner',
  LIST = 'List',
  PAGE = 'Page',
  NAV_LINK_ITEM = 'NavCategoryItem',
  SUB_LINK_COLLECTION_ITEM = 'NavCategoryItemSubcategoriesCollection',
  HOME_HERO = 'HomeHero',
  HOME_HERO_SINGLE_IMAGE = 'HomeHeroSingleImage',
  HOME_SUB_FEATURE_RIGHT = 'HomeSubFeatureRight',
  HOME_MAIN_HERO_LEFT = 'HomeMainHeroLeft',
  PROGRAM_FEATURE_WITH_ICON = 'ProgramFeatureWithIcon',
  PROGRAM_FEATURE_WITH_IMAGE = 'ProgramFeatureWithImage',
  EXAMPLE_PRODUCT_ITEM = 'ExampleProductItem',
  HOME_PAGE_BODY_COLLECTION = 'HomePageBodyCollection',
  PROGRAM_BENEFIT_ITEM = 'ProgramBenefitItem',
  LOGGED_IN_HOME_BANNER = 'LoggedInHomeBanner',
  MEMBER_PANEL_BULLET_ITEM = 'MemberPanelBulletItem',
  IMAGE_BANNER_WITH_TEXT_OVERLAY = 'ImageBannerWithTextOverlay',
  // Existing from LDP below
  // TODO: Flush out unused constants near end of project
  BANNER_IN_PAGE = 'BannerInPage',
  BLOCK_QUOTE = 'BlockQuote',
  BUTTON_GROUP_LIST = 'ButtonGrouplist',
  BUTTON_IN_PAGE = 'ButtonInPage',
  CATEGORY_PAGE = 'CategoryPage',
  CATEGORY_PAGE_BODY_COLLECTION = 'CategoryPageBodyCollection',
  D365_FORM = 'D365Form',
  IFRAME_VIDEO = 'Iframe',
  FAQ_ITEM = 'FaqItem',
  FEATURE_BANNER = 'FeatureBanner',
  GLOBAL_HEADER_BANNER = 'GlobalHeaderBanner',
  HEADER_OVERLAY_BANNER = 'HeaderOverlayBanner',
  HEADER_OVERLAY_BUTTON = 'HeaderOverlayButton',
  HEADER_OVERLAY_TILES = 'HeaderOverlayTiles',
  HOME_OVERLAY_SUBFEATURE = 'HomeOverlaySubFeature',
  HOME_HERO_BANNER = 'HomeHeroBanner',
  IMAGE_IN_TEXT = 'ImageInText',
  PAGE_ALERT_BANNER = 'PageAlertBanner',
  PAGE_BODY_COLLECTION = 'PageBodyCollection',
  PAGE_BODY_INSET_BLOCK = 'PageBodyInsetBlock',
  PAGE_BODY_TEXT = 'PageBodyText',
  TEXT_BANNER = 'TextBanner',
  IMAGE_FULL_WIDTH_BANNER = 'ImageBannerFullWidth',
  IMAGE_BANNER_THREE_UP = 'ImageBanner2Up',
  PAGE_SUMMERY_BLOCK = 'PageSummaryBlock'
}

export const PROVINCE_MAP: { [key: string]: string } = {
  Alberta: 'AB',
  'British Columbia': 'BC',
  Manitoba: 'MB',
  'New Brunswick': 'NB',
  'Newfoundland and Labrador': 'NL',
  'Northwest Territories': 'NT',
  'Nova Scotia': 'NS',
  Nunavut: 'NU',
  Ontario: 'ON',
  'Prince Edward Island': 'PE',
  Québec: 'QC',
  Saskatchewan: 'SK',
  Yukon: 'YT'
};

export const NEXT_PREVIEW_COOKIE = '__next_preview_data';
export const AUTH_TOKEN_COOKIE = 'authToken';
export const REFRESH_TOKEN_COOKIE = 'refreshToken';
export const SKIP_HOLDING_PAGE_COOKIE = 'skipHoldingPage';
export const USER_ID_COOKIE = 'userId';
export const CUSTOMER_ID_COOKIE = 'customerId';

export enum EXTRAS_TIER_CODE {
  TIER1 = 'TIER1',
  TIER2 = 'TIER2'
}

export enum EXTRAS_TIER_NAME {
  TIER1 = 'Fan',
  TIER2 = 'Fave'
}

export enum EXTRAS_POINTS_PER_DOLLAR {
  TIER1 = 10,
  TIER2 = 15
}

export const EXTRAS_TIER2_THRESHOLD = 1000;

export const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
];

export const years = ['2024', '2023', '2022'];
export const AuthTokenMaxAge = 60 * 60 * 24 * 999; // 999 days
