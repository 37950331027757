import { ReactElement } from 'react';
import { useRouter } from 'next/router';
import { Box, Container, Flex } from '@chakra-ui/react';
import { MainNavigationQueryItemCollection } from 'types/cms/queries';
import LDLink from 'components/common/LDLink';

interface DesktopNavigationProps {
  navItems?: MainNavigationQueryItemCollection;
}

const DesktopNavigation = ({ navItems }: DesktopNavigationProps): ReactElement => {
  const { asPath } = useRouter();

  return (
    <Box backgroundColor="primary.dark">
      <Container maxW="container.xxl">
        <Flex>
          {navItems?.map(({ title, route }) => {
            if (!route) return null;
            return (
              <LDLink
                href={route}
                key={title}
                as="a"
                paddingY="3"
                paddingX="8"
                backgroundColor={asPath.includes(route) ? 'primary.hover' : 'primary.dark'}
                _hover={{ backgroundColor: 'primary.hover' }}
                color="white"
                fontWeight="medium"
                textAlign="center"
              >
                {title}
              </LDLink>
            );
          })}
        </Flex>
      </Container>
    </Box>
  );
};

export default DesktopNavigation;
