import { AccordionButton, AccordionItem, AccordionPanel, Flex, Text, Box } from '@chakra-ui/react';
import { ReactElement } from 'react';

import { AddIcon, MinusIcon } from '@chakra-ui/icons';
import MDXComponent from 'components/Mdx';

export interface FaqItemProps {
  question: string;
  answer: string;
  index: number;
}

const FaqItem = ({ question, answer, index }: FaqItemProps): ReactElement => {
  return (
    <AccordionItem border="1px solid" style={{ borderColor: '#e1e1e3' }} id={`faq-item-${index}`}>
      {({ isExpanded }) => (
        <>
          <AccordionButton
            paddingX={{ base: '1rem', md: '1.8rem' }}
            paddingY={{ base: '1rem', md: '1.8rem' }}
          >
            <Flex justifyContent="space-between" alignItems="center" width="100%">
              <Box>
                <Box
                  fontSize={{ base: 'md', md: 'lg' }}
                  lineHeight="1.5"
                  textAlign="left"
                  color={isExpanded ? 'primary.default' : 'black'}
                  pr={2.5}
                >
                  <MDXComponent>{question}</MDXComponent>
                </Box>
              </Box>

              {isExpanded ? (
                <MinusIcon
                  color="extras.default"
                  opacity="0.6"
                  width={{ base: '3', md: '4' }}
                  height={{ base: '3', md: '4' }}
                />
              ) : (
                <AddIcon
                  opacity="0.4"
                  width={{ base: '3', md: '4' }}
                  height={{ base: '3', md: '4' }}
                />
              )}
            </Flex>
          </AccordionButton>
          <AccordionPanel p={16} borderTop="solid 1px" borderTopColor="shades.100">
            <MDXComponent>{answer}</MDXComponent>
          </AccordionPanel>
        </>
      )}
    </AccordionItem>
  );
};

export default FaqItem;
