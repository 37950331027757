import { observer } from 'mobx-react';
import {
  Box,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerBody,
  DrawerHeader,
  Flex,
  Stack,
  Text,
  useDisclosure,
  Divider,
  Image
} from '@chakra-ui/react';
import { ArrowForwardIcon, ArrowUpIcon, HamburgerIcon } from '@chakra-ui/icons';

import photoLabLogo from '../../public/assets/photo-lab-icon.png';
import { Banner } from 'components/CMS';
import { URLS } from 'utils/constants';
import ProxiedImage from 'components/common/ProxiedImage';
import LDLink from 'components/common/LDLink';
import { useStore } from 'store';
import { useRouter } from 'next/router';
import { GTMGeneralEvent } from 'utils/gtmHelpers';

const MobileHeader = ({ simpleHeader }: { simpleHeader: boolean }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { cmsStore, extrasStore } = useStore();
  const { push } = useRouter();
  const { globalHeaderBanner, mainNavigation } = cmsStore;

  if (simpleHeader) {
    return (
      <Box display={{ md: 'none' }}>
        <Flex
          minH="90"
          backgroundColor="primary.default"
          paddingX={{ base: '10', sm: '5' }}
          alignItems="center"
          justifyContent="center"
        >
          <Image src="/assets/ld-extras-logo.png" width="256px" height="40px" alt="LDExtras logo" />
        </Flex>
        <Box bgColor="extras.olive" h="0.75em" w="100%" mt="0.25em" />
      </Box>
    );
  }
  return (
    <Box display={{ md: 'none' }}>
      {globalHeaderBanner && <Banner {...globalHeaderBanner} mobile isGlobalHeaderBanner />}
      <Flex
        backgroundColor="primary.default"
        paddingX={{ base: '10', sm: '5' }}
        alignItems="center"
        justifyContent="center"
        minH="90"
      >
        <Flex flex={1} alignItems="center" justifyContent="center">
          <LDLink href="/">
            <Box as="a" d="flex">
              <Image
                src="/assets/ld-extras-logo.png"
                width="256px"
                height="40px"
                alt="LDExtras logo"
              />
            </Box>
          </LDLink>
        </Flex>
      </Flex>
      <Flex backgroundColor="primary.dark" padding="3" alignItems="center">
        <HamburgerIcon onClick={onOpen} color="white" w={9} h={9} marginRight={4} />
        <Flex flex={2} justifyContent="flex-end">
          {extrasStore?.isLoggedIn ? (
            <Stack spacing="1" direction="row" justifyContent="center" alignItems="center">
              <Text color="white" fontSize="sm" fontWeight="semibold">
                Welcome back, {extrasStore?.firstName}
              </Text>
              <Box height="16px">
                <Divider orientation="vertical" borderColor="shades.300" />
              </Box>
              <Text
                as="button"
                onClick={() => {
                  push(URLS.SIGN_IN);
                  // Delay to prevent flash of empty dashboard
                  setTimeout(() => {
                    extrasStore.logout();
                  }, 200);
                }}
                color="shades.250"
                fontSize="sm"
                fontWeight="semibold"
                _hover={{ color: 'shades.400', textDecoration: 'none' }}
              >
                Sign Out
              </Text>
            </Stack>
          ) : (
            <Text fontWeight="bold" fontSize="sm">
              <LDLink href={URLS.SIGN_IN}>
                <Text as="a" color="white" _hover={{ color: 'shades.200' }}>
                  Sign In
                </Text>
              </LDLink>
              <Text as="span" color="shades.300">
                {' '}
                or{' '}
              </Text>
              <LDLink href={URLS.SIGN_UP} onClick={() => GTMGeneralEvent({ event: 'sign_up' })}>
                <Text as="a" color="shades.300" _hover={{ color: 'shades.400' }}>
                  Sign Up
                </Text>
              </LDLink>
            </Text>
          )}
          {/* <Text fontWeight="bold" color="white">
            <LDLink href={URLS.SIGN_IN} _hover={{ color: 'shades.300' }}>
              Sign In
            </LDLink>
            <Text as="span" color="shades.300">
              {' '}
              or{' '}
            </Text>
            <LDLink href={URLS.SIGN_UP} color="shades.300" _hover={{ color: 'shades.400' }}>
              Sign Up
            </LDLink>
          </Text> */}
        </Flex>
      </Flex>
      <Drawer isOpen={isOpen} placement="left" size="full" onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton color="white" size="lg" />
          <DrawerHeader
            backgroundColor="primary.default"
            paddingX="4"
            paddingY="5"
            minHeight="60px"
          />
          {/* Main Navigation */}
          <DrawerBody padding="0" backgroundColor="shades.50">
            {mainNavigation?.map(
              ({ title, route }: { title: string; route: string }, index: number) => {
                return (
                  <LDLink key={`mobile-nav-${index}`} href={route || '/'}>
                    <Flex
                      justifyContent="space-between"
                      alignItems="center"
                      borderBottom="1px solid #C6C6C6"
                      paddingX="5"
                      paddingY="4"
                      backgroundColor="white"
                      onClick={onClose}
                      _hover={{ bg: 'shades.100' }}
                    >
                      {title === 'My LDExtras' ? (
                        <Text color="primary.dark" fontSize="lg" fontWeight="medium">
                          My LD
                          <Text as="span" fontStyle="italic" color="extras.olive">
                            Extras
                          </Text>
                        </Text>
                      ) : (
                        <Text fontSize="lg" fontWeight="medium" color="primary.dark">
                          {title}
                        </Text>
                      )}
                      <ArrowForwardIcon color="primary.dark" w={6} h={6} />
                    </Flex>
                  </LDLink>
                );
              }
            )}
            {/* Bottom Nav */}
            {[
              { title: 'Shop LD.com', href: 'https://www.londondrugs.com/' },
              { title: 'photolab', href: 'https://photolab.londondrugs.com/' },
              { title: 'Pharmacy', href: 'https://pharmacy.londondrugs.com/' }
            ].map(({ title, href }, index: number) => (
              <a href={href} target="_blank" rel="noreferrer" key={`mobile-nav-links-${index}`}>
                <Flex
                  justifyContent="space-between"
                  alignItems="center"
                  borderBottom="1px solid #C6C6C6"
                  paddingX="5"
                  paddingY="4"
                  _hover={{ bg: 'shades.100' }}
                >
                  {title === 'photolab' ? (
                    <Flex alignItems="center">
                      <ProxiedImage
                        src={photoLabLogo}
                        width={16}
                        height={17}
                        layout="fixed"
                        alt="PhotoLab logo"
                      />
                      <Text marginLeft="1" fontSize="lg" fontWeight="medium" color="primary.dark">
                        {title}
                      </Text>
                    </Flex>
                  ) : (
                    <Text fontSize="lg" fontWeight="medium" color="primary.dark">
                      {title}
                    </Text>
                  )}

                  <ArrowUpIcon
                    color="primary.dark"
                    w={6}
                    h={6}
                    style={{ transform: 'rotate(45deg)' }}
                  />
                </Flex>
              </a>
            ))}
            <Stack spacing="6" marginTop="6" paddingX="5">
              <LDLink
                href="https://www.londondrugs.com/store-flyers/eflyer.html"
                target="_blank"
                rel="noreferrer"
              >
                <Text fontSize="sm" fontWeight="bold" _hover={{ color: 'primary.default' }}>
                  Flyers
                </Text>
              </LDLink>
              <LDLink href="https://www.londondrugs.com/locations" target="_blank" rel="noreferrer">
                <Text fontSize="sm" fontWeight="bold" _hover={{ color: 'primary.default' }}>
                  Locations
                </Text>
              </LDLink>
            </Stack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Box>
  );
};

export default observer(MobileHeader);
