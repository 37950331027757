export enum FETCH_METHODS {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  PATCH = 'PATCH',
  DELETE = 'DELETE'
}

export const ENDPOINTS = {
  AUTH_TOKEN: '/oauth/token',
  LOGOUT: '/logoutuser',
  PROFILE: '/loyalty/account/profile',
  PASSWORD_RESET: '/loyalty/account/password/reset',
  UPDATE_PASSWORD: (userId: number | string): string => `/loyalty/account/${userId}/password`,
  SIGN_UP: '/loyalty/consumers/enroll',
  MEMBER: (userId: number | string): string => `/loyalty/members/${userId}`,
  MEMBER_SUMMARY: (userId: number | string): string => `/loyalty/members/${userId}/summary`,
  REWARDS_SUMMARY: (userId: number | string): string => `/loyalty/members/${userId}/rewardsSummary`,
  CERTIFICATE_SUMMARY: (userId: number | string): string =>
    `/loyalty/members/${userId}/certificateSummary`,
  CERTIFICATES: (userId: number | string): string => `/loyalty/accounts/${userId}/certificates`,
  REWARDS: (userId: number | string): string => `/loyalty/accounts/${userId}/rewards`,
  GET_MEMBER_INTERESTS: (userId: number | string): string =>
    `/loyalty/members/${userId}/preferences/search`,
  GET_ALL_INTERESTS: '/reference/standardReferenceData/refPreference?includeInactive=false',
  UPDATE_MEMBER_INTERESTS: (userId: number | string): string =>
    `/loyalty/members/${userId}/preferences`,
  BRIEF: (userId: number | string): string => `/loyalty/members/${userId}/brief`,
  TIER_SUMMARY: (userId: number | string): string => `/loyalty/members/${userId}/tierSummary`,
  OFFERS: (userId: number | string): string =>
    `/loyalty/accounts/${userId}/offer/searchAccountOffers`,
  REGISTER_BIRTHDAY_OFFER: (userId: number | string, offerId: string): string =>
    `/loyalty/accounts/${userId}/offer/${offerId}`,
  REGISTER_OFFER: (userId: number | string): string => `/loyalty/accounts/${userId}/offer`,
  COUPONS: (userId: number | string): string => `/loyalty/accounts/${userId}/coupons`,
  PRODUCTS: '/fulfillment/catalog/LDEXTRAS/products/search',
  PRODUCT_DETAILS: (productCode: string): string =>
    `/fulfillment/catalog/products/${productCode}?programCode=LDEXTRAS&redeemSegment=BASE&cardProductCode=LDCRDPRD`,
  REDEEM_PRODUCT: '/activity/redemptions',
  TRANSACTIONS: (userId: string | number): string => `/loyalty/accounts/${userId}/transactions`,
  REDEMPTIONS: (userId: string | number): string => `/loyalty/accounts/${userId}/redemptions`,
  COMMUNICATION_PREFERENCES: (userId: string | number): string =>
    `/loyalty/members/${userId}/communicationPreferences`,
  MEMBER_SEARCH: '/loyalty/members/search',
  MEMBER_EMAIL: (customerId: number | string): string =>
    `/loyalty/customers/${customerId}/customerEmail`,
  MEMBER_PHONE: (customerId: number | string): string =>
    `/loyalty/customers/${customerId}/customerPhone`
};

export enum STATUSES {
  ACTIVE = 'A',
  INACTIVE = 'I'
}

export const INVALID_GRANT_ERROR = 'invalid_grant';

export const ADMIN_USERNAME = process.env.NEXT_PUBLIC_KOBIE_ADMIN_USERNAME ?? '';
export const ADMIN_PASSWORD = process.env.NEXT_PUBLIC_KOBIE_ADMIN_PASSWORD ?? '';
