import { CheckCircleIcon } from '@chakra-ui/icons';
import { Skeleton, Stack, Text } from '@chakra-ui/react';
import { Fragment, ReactElement } from 'react';
import { EXTRAS_POINTS_PER_DOLLAR, EXTRAS_TIER_CODE } from 'utils/constants';

interface PointsInfoProps {
  remainingProgress?: number;
  remainingRequalificationProgress?: number;
  isRequalified?: boolean;
  tierCode: 'TIER1' | 'TIER2';
  balance: number;
}

const PointsInfo = ({
  remainingProgress,
  remainingRequalificationProgress,
  tierCode,
  balance,
  isRequalified
}: PointsInfoProps): ReactElement => {
  return (
    <Stack spacing="6">
      {typeof tierCode === 'string' ? (
        <Fragment>
          <Stack direction="row" alignItems="center">
            <CheckCircleIcon width="30px" height="30px" color="extras.olive" />
            <Text fontStyle="italic" fontSize={{ base: 'sm', md: 'md' }}>
              You are earning{' '}
              <Text as="span" fontWeight="bold">
                {EXTRAS_POINTS_PER_DOLLAR[tierCode]} points
              </Text>{' '}
              per dollar.
            </Text>
          </Stack>
          <Stack direction="row" alignItems="center">
            <CheckCircleIcon width="30px" height="30px" color="extras.olive" />
            {tierCode === EXTRAS_TIER_CODE.TIER1 ? (
              <Text fontStyle="italic" fontSize={{ base: 'sm', md: 'md' }}>
                You are{' '}
                <Text as="span" fontWeight="bold">
                  ${Number(remainingProgress).toLocaleString()}
                </Text>{' '}
                away from being an{' '}
                <Text as="span" fontWeight="bold">
                  LD
                </Text>
                Extras Fave.
              </Text>
            ) : !isRequalified ? (
              <Text fontStyle="italic" fontSize={{ base: 'sm', md: 'md' }}>
                You are{' '}
                <Text as="span" fontWeight="bold">
                  ${Number(remainingRequalificationProgress).toLocaleString()}
                </Text>{' '}
                away from being an{' '}
                <Text as="span" fontWeight="bold">
                  LD
                </Text>
                Extras Fave for another year.
              </Text>
            ) : (
              <Text fontStyle="italic" fontSize={{ base: 'sm', md: 'md' }}>
                Congrats! You're a Fave and are earning more points!
              </Text>
            )}
          </Stack>
          <Stack direction="row" alignItems="center">
            <CheckCircleIcon width="30px" height="30px" color="extras.olive" />
            <Text fontStyle="italic" fontSize={{ base: 'sm', md: 'md' }}>
              {balance < 250
                ? 'Keep collecting to unlock rewards'
                : ' Rewards are waiting! Exchange your points now.'}
            </Text>
          </Stack>
        </Fragment>
      ) : (
        <Fragment>
          <Skeleton height="30px" />
          <Skeleton height="30px" />
          <Skeleton height="30px" />
        </Fragment>
      )}
    </Stack>
  );
};

export default PointsInfo;
