import { Flex, Image, Text } from '@chakra-ui/react';
import React from 'react';
import { HomeHeroBannerCMSData } from 'types/cms/homeFragments';
import LDThemeButton from 'components/common/LDThemeButton';
import LDLink from 'components/common/LDLink';

export interface HomeHeroBannerProps extends HomeHeroBannerCMSData {}

const HomeHeroBanner = ({
  text,
  backgroundColor,
  textColor,
  button1Link,
  button1Icon,
  button1Text,
  button2Link,
  button2Icon,
  button2Text
}: HomeHeroBannerProps): JSX.Element => {
  return (
    <Flex
      flexDir={{ base: 'column', md: 'row' }}
      justifyContent="center"
      alignItems="center"
      bgColor={backgroundColor}
      h="auto"
      p="2rem 1rem"
      flexWrap="wrap"
    >
      <Text
        color={textColor}
        fontSize={{ base: 'md', sm: '2xl' }}
        mt={{ base: '-1rem', md: '0' }}
        whiteSpace="nowrap"
      >
        {text}
      </Text>
      {(button1Link || button2Link) && (
        <Flex
          flexDir="row"
          justifyContent={{ base: 'center', sm: 'space-between' }}
          alignItems="center"
          flexWrap={{ base: 'wrap', sm: 'nowrap' }}
          mt={{ base: '0.5rem', md: '0' }}
          px="1rem"
        >
          {button1Link && (
            <LDLink href={button1Link} as="a">
              <LDThemeButton
                
                bgColor="#fff"
                w="auto"
                m="0.5rem"
                p={4}
                leftIcon={
                  button1Icon?.url ? (
                    <Image src={button1Icon?.url} alt={button1Icon?.description} sizes="icon" />
                  ) : undefined
                }
                constrainerProps={{ d: 'flex', justifyContent: 'center' }}
              >
                {button1Text}
              </LDThemeButton>
            </LDLink>
          )}
          {button2Link && (
            <LDLink href={button2Link} as="a">
              <LDThemeButton
                
                bgColor="#fff"
                w="auto"
                m="0.5rem"
                p={4}
                leftIcon={
                  button2Icon?.url ? (
                    <Image src={button2Icon?.url} alt={button2Icon?.description} sizes="icon" />
                  ) : undefined
                }
                constrainerProps={{ d: 'flex', justifyContent: 'center' }}
              >
                {button2Text}
              </LDThemeButton>
            </LDLink>
          )}
        </Flex>
      )}
    </Flex>
  );
};

export default HomeHeroBanner;
