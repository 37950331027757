import React, { ReactElement } from 'react';
import cookie from 'cookie-cutter';

const isClient = typeof window !== 'undefined';

const expiredDateInUTC = (additionalDays: number) => {
  const expiredDate = new Date();

  expiredDate.setDate(expiredDate.getDate() + additionalDays);

  return expiredDate.toUTCString();
};

export interface SmartBannerProps {
  daysHidden?: number;
  daysReminder?: number;
  title: string;
  author: string;
  position: string;
  button?: string;
  storeText?: {
    ios: string;
    android: string;
  };
  appMeta?: { ios: string; android: string };
}

const SmartBanner = ({
  daysHidden = 15,
  daysReminder = 90,
  title,
  author,
  position = 'top',
  button = 'View',
  storeText = {
    ios: 'On the App Store',
    android: 'In Google Play'
  },
  appMeta = {
    ios: 'apple-itunes-app',
    android: 'google-play-app'
  }
}: SmartBannerProps): ReactElement => {
  const [appId, setAppId] = React.useState<string | null>(null);
  const [type, setType] = React.useState<'ios' | 'android'>();
  const appStoreLanguage = isClient ? (window.navigator.language || '').slice(-2) || 'us' : 'us';
  const settingsObj = React.useMemo(() => {
    return {
      ios: {
        appMeta: () => appMeta.ios,
        iconRels: ['apple-touch-icon-precomposed', 'apple-touch-icon'],
        getStoreLink: () => `https://itunes.apple.com/${appStoreLanguage}/app/id`
      },
      android: {
        appMeta: () => appMeta.android,
        iconRels: ['android-touch-icon', 'apple-touch-icon-precomposed', 'apple-touch-icon'],
        getStoreLink: () => 'http://play.google.com/store/apps/details?id='
      }
    };
  }, [appMeta, appStoreLanguage]);

  const show = () => {
    if (isClient) {
      document.querySelector('html')?.classList.add('smartbanner-show');
    }
  };

  React.useEffect(() => {
    let deviceType;
    if (/Android/i.test(window.navigator.userAgent)) {
      deviceType = 'android';
    }
    if (deviceType === 'android') {
      setType(deviceType);
      const settings = settingsObj[deviceType];

      const meta = document.querySelector(`meta[name="${settings.appMeta()}"]`);

      if (meta) {
        let appId = '';

        const content = /app-id=([^\s,]+)/.exec(meta?.getAttribute('content') || '');

        appId = content && content[1] ? content[1] : appId;

        setAppId(appId);
      }
    }
    return () => {
      const documentRoot = document.querySelector('html');

      if (documentRoot) {
        documentRoot.classList.remove('smartbanner-show');
        documentRoot.classList.remove('smartbanner-margin-top');
        documentRoot.classList.remove('smartbanner-margin-bottom');
      }
    };
  }, [settingsObj]);

  if (!isClient) {
    return <div />;
  }

  // Don't show banner when:
  // 1) if device isn't iOS or Android
  // 2) user dismissed banner,
  // 3) or we have no app id in meta
  if (!type || cookie.get('smartbanner-closed') || cookie.get('smartbanner-installed') || !appId) {
    return <div />;
  }

  const settings = settingsObj[type];

  show();

  const hide = () => {
    if (isClient) {
      document.querySelector('html')?.classList.remove('smartbanner-show');
    }
  };

  const close = () => {
    hide();
    cookie.set('smartbanner-closed', 'true', {
      path: '/',
      expires: expiredDateInUTC(daysHidden)
    });
  };

  const install = () => {
    hide();
    cookie.set('smartbanner-installed', 'true', {
      path: '/',
      expires: expiredDateInUTC(daysReminder)
    });
  };

  const retrieveInfo = () => {
    const link = settings.getStoreLink() + appId;
    const inStore = `
      Free - ${storeText[type]}`;
    let icon;

    if (isClient) {
      for (let i = 0, max = settings.iconRels.length; i < max; i++) {
        const rel = document.querySelector(`link[rel="${settings.iconRels[i]}"]`);

        if (rel) {
          icon = rel.getAttribute('href');
          break;
        }
      }
    }

    return {
      icon,
      link,
      inStore
    };
  };

  const { icon, link, inStore } = retrieveInfo();
  const wrapperClassName = `smartbanner smartbanner-${type} smartbanner-${position}`;
  const iconStyle = {
    backgroundImage: `url(${icon})`
  };

  return (
    <div className={wrapperClassName}>
      <div className="smartbanner-container">
        <button type="button" className="smartbanner-close" aria-label="close" onClick={close}>
          &times;
        </button>
        <span className="smartbanner-icon" style={iconStyle} />
        <div className="smartbanner-info">
          <div className="smartbanner-title">{title}</div>
          <div className="smartbanner-author">{author}</div>
          <div className="smartbanner-description">{inStore}</div>
        </div>
        <div className="smartbanner-wrapper">
          <a href={link} onClick={install} className="smartbanner-button">
            <span className="smartbanner-button-text">{button}</span>
          </a>
        </div>
      </div>
    </div>
  );
};

export default SmartBanner;
