import { useState } from 'react';
import { FaqItemCMSData, ListItemsCMSData } from 'types/cms/fragments';
import { stringToCamelCase } from 'utils';

export interface FAQTabData {
  [key: string]: { tabName?: string; faqItems?: FaqItemCMSData[] };
}

const useFaqData = ({
  listData
}: {
  listData: ListItemsCMSData[];
}): {
  currentFaqListKey: string | undefined;
  setCurrentFaqList: (key?: string | undefined) => void;
  tabNames: string[] | undefined;
  faqData: FAQTabData;
} => {
  const sortedFAQItemsKeys = listData
    ? [...new Set((listData as FaqItemCMSData[])?.map((item: FaqItemCMSData) => item?.faqType))]
    : undefined;

  const responseObject: FAQTabData = {};

  if (sortedFAQItemsKeys && sortedFAQItemsKeys?.length >= 1) {
    [...new Set(sortedFAQItemsKeys)]?.forEach((keyName: string) => {
      responseObject[`${stringToCamelCase(keyName)}`] = {
        tabName: keyName,
        faqItems: (listData as FaqItemCMSData[])?.filter((faqItem: FaqItemCMSData) => {
          return faqItem?.faqType === keyName;
        })
      };
    });
  }

  const [currentFaqListKey, setCurrentFaqListKey] = useState<string | undefined>(
    stringToCamelCase(sortedFAQItemsKeys?.[0])
  );

  const setCurrentFaqList = (key?: string) => {
    setCurrentFaqListKey(stringToCamelCase(key));
  };

  return {
    currentFaqListKey,
    setCurrentFaqList,
    tabNames: sortedFAQItemsKeys,
    faqData: responseObject
  };
};

export default useFaqData;
