import { CONTENT_TYPES } from 'utils/constants';

export const IMAGE_BANNER = `
fragment ${CONTENT_TYPES?.IMAGE_BANNER}Fragment on ${CONTENT_TYPES?.IMAGE_BANNER} {
  __typename
  title
  linkUrl
  image {
    ...Image
  }
  mobileImage {
    ...Image
  } 
}    
`;

export const IMAGE_BANNER_WITH_TEXT_OVERLAY = `
fragment ${CONTENT_TYPES?.IMAGE_BANNER_WITH_TEXT_OVERLAY}Fragment on ${CONTENT_TYPES?.IMAGE_BANNER_WITH_TEXT_OVERLAY} {
  __typename
  title
  headline
  headlineColor
  subhead
  subheadColor
  buttonText
  buttonTextLink
  overlayBackgroundTrimColor
  imageCollection {
    items {
    ...Image
  } 
  }
  mobileImageCollection {
    items {
    ...Image
  } 
  }
}    
`;
